export enum EstimateType {
    USEstimate = 'USEstimate',
    InternationalEstimate = 'InternationalEstimate',
    BetaEstimate = 'BetaEstimate'
}


export enum EstimateTitle {
    USEstimate = 'U.S. Cost of Capital Estimates',
    InternationalEstimate = 'International Cost of Capital Estimates',
    BetaEstimate = 'Company-level Beta Estimates',
    SicToGicsMapping = 'SIC-to-GICS Mapping'
}