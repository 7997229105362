import { WorkbookSheet, WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { DataSourceDetails, IntlDataSource } from '../models/DataSourceDetails';
import { CocInputExcelUtill } from './cocinputs-export-utill';
import * as moment from 'moment';
import { EnumSubsType } from '../models/userSubscriptionTypes';

export class ExportAboutSheet {


    public static columnWidth = 125;
    public static intlSheetReuiredCountries = {
        RiskFreeRateCountries: ['Australia', 'Canada', 'Germany', 'Japan', 'Switzerland', 'United Kingdom', 'United States'],
        ErpCountries: ['Australia', 'Austria', 'Belgium', 'Canada', 'France', 'Germany', 'Ireland', 'Italy', 'Japan', 'Netherlands', 'New Zealand', 'South Africa', 'Spain', 'Switzerland', 'United Kingdom', 'United States']
    }
    private static KrollShadow: string = '#4d4d4f';


    public static getAboutDataSourceSheet(aboutData: DataSourceDetails): WorkbookSheet {

        const workBookSheetRows: WorkbookSheetRow[] = [];

        const aboutSheet: WorkbookSheet = {
            name: 'About',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [CocInputExcelUtill.styleMainHeader('Source', 1)]
                },
                {
                    cells: this.getSourceDetails(aboutData.SubType)
                },
                {
                    cells: [CocInputExcelUtill.textCell('Exported on: ' + moment(new Date()).format('MM/DD/YYYY'), "left", false, false)]
                },
                {   cells: [{}]   },
                {
                    cells: [CocInputExcelUtill.styleMainHeader('Data Sources', 1)],
                },
                {
                    cells: [CocInputExcelUtill.textCell('Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.', "left", false, false)]
                },
                {   cells: [{}]     },
                {
                    cells: [CocInputExcelUtill.styleMainHeader('Data Point', 1), CocInputExcelUtill.styleMainHeader('Investor Perspective Currency', 1),
                             CocInputExcelUtill.styleMainHeader('Source', 1),  CocInputExcelUtill.styleMainHeader('Module', 1)]
                }
            ]
        }

        aboutData.AboutSpreadSheetData.forEach(data => {
            const row: WorkbookSheetRow = {
                cells: [{
                    value: data.DataPoint,
                    bold: true,
                    colSpan: 1,
                    color: this.KrollShadow,
                    fontFamily: 'Arial'
                },
                {
                    value: data.InvestorPerspectiveCurrency,
                    colSpan: 1,
                    color: this.KrollShadow,
                    fontFamily: 'Arial'
                },
                {
                    value: data.Source,
                    colSpan: 1,
                    color: this.KrollShadow,
                    fontFamily: 'Arial'
                },
                {
                    value: data.Module,
                    colSpan: 1,
                    color: this.KrollShadow,
                    fontFamily: 'Arial'
                }],
            };
            workBookSheetRows.push(row);
        });
        aboutSheet.rows?.push(...workBookSheetRows);
        return aboutSheet;
    }


    public static createUsData(usData: any): WorkbookSheetRow[] {
        let data = this.aboutDetails()
        let sheetData: WorkbookSheetRow[] = [];

        Object.entries(data).forEach((arayData: any) => {
            let sheetcellrow: WorkbookSheetRow = {
                cells: [

                    {
                        value: arayData[0],
                        color: CocInputExcelUtill.krollshadow,
                        fontSize: 14.66,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        bold: true,
                    },

                    CocInputExcelUtill.textCell(arayData[0] == 'Industry Risk Premium' ? arayData[1] : usData[arayData[1]], "left", false, false)


                ]
            }
            sheetData.push(sheetcellrow);
        });
        return sheetData;
    }


    public static CreatIntlData(intlData: IntlDataSource[]): WorkbookSheetRow[] {
        const intlSheetRows: WorkbookSheetRow[] = intlData.map((item: IntlDataSource) => {
            return {
                cells: [CocInputExcelUtill.textCell(item.SourceName, "left", true, false), CocInputExcelUtill.textCell(item.SourceValue, "left", false, false)]
            }
        });

        return intlSheetRows;

    }


    public static getSourceDetails(subTye: number): WorkbookSheetRowCell[] {

        if (subTye == EnumSubsType.Coc) {
            return [CocInputExcelUtill.textCell('Kroll Cost of Capital Navigator: U.S. Cost of Capital Inputs Dataset, U.S. Industry Benchmarking Dataset', "left", false, false)]
        } else if (subTye == EnumSubsType.Ine) {
            return [CocInputExcelUtill.textCell('Kroll Cost of Capital Navigator: International Cost of Capital Inputs Dataset, International Industry Benchmarking Dataset', "left", false, false)]
        } else {
            return [CocInputExcelUtill.textCell('Kroll Cost of Capital Navigator: U.S. Cost of Capital Inputs Dataset, U.S. Industry Benchmarking Dataset, International Cost of Capital Inputs Dataset, International Industry Benchmarking Dataset', "left", false, false)]
        }


    }

    private static aboutDetails(): any {
        return {
            'Spot Treasury Rates': 'SpotTreasuryRates',
            'Kroll Normalized Risk-free Rate': 'KrollNormalizedRiskFreeRate',
            'Kroll Recommended ERP': 'KrollRecommendedERP',
            'Supply-side Long-term ERP': 'SupplySideLongTermERP',
            'Historical Long-term ERP': 'HistoricalLongtermERP',
            'CRSP Deciles Size Study': 'CRSPDecilesSizeStudy',
            'Risk Premium Report Size Study': 'RiskPremiumReportSizeStudy',
            'High Financial Risk Study': 'HighFinancialRiskStudy',
            'Risk Premium Report Risk Study': 'RiskPremiumReportRiskStudy',
            'Full Information Beta': 'FullInformationBeta',
            'OLS Beta': 'OlsBeta',
            'Sum Beta': 'SumBeta',
            'Vasicek-Adjusted Beta': 'Vasicken',
            'Blume-Adjusted Beta': 'Blume',
            'Peer Group': 'PeerGroup',
            'Industry Risk Premium': 'Source dependent on Beta and ERP used to calculate the Industry Risk Premium',
            'Debt Betas': 'DebtBetas',

        }
    }








}