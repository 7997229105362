import { Component, OnInit } from '@angular/core';
import { ContextualString } from '@concurrency/core';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { CocInputValues } from '../../models/cocInputs';
import { CocInputsSharedService } from '../../service/cocInputShared.service';
import { DecilesPortfolio, HighFinancialRiskStudy, RiskPremiumSizeStudyPortfolio } from '../../models/cocInputsTabs';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { DataStudy, RiskPremiumReportSizeStudy, enumExcelExportSheets, sizePremiumResourcesData, RiskPremiumDataStudy } from '../../cocViewInputs';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Spinner } from '@concurrency/angular';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { CocinputsExcelExportService } from '../../service/cocinputs-excel-export.service';
import { CocInputResourcesList } from '../../coc-input-resources-list.model';
import { RegressionValues } from 'src/app/_api/responses/RprRegressionData.response';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { crspDecileCharacter, spRPRCharacter } from '../../commonHeaders';
import { SizePremiumService } from '../../service/size-premium.service';
import { CrspCharacteristicsData, CrspCharacteristicsWithPortfolioData } from '../../models/crspCharacteristicData';
import { RPRsharedService } from '../../service/rpr-shared-service';
import { RiskPremiumCharacteristicsData } from '../../models/rprCharacteristicsData';
import { EnumSubsType } from '../../models/userSubscriptionTypes';


@Component({
    selector: 'size-premium',
    templateUrl: './size-premium.component.html',
    styleUrls: ['./size-premium.component.scss']
})
export class SizePremiumComponent implements OnInit {


    constructor
        (
            public spinner: Spinner,
            private sharedCocInputService: CocInputsSharedService,
            private exportService: CocinputsExcelExportService,
            private userStore: UserStore,
            private sizePremiumService: SizePremiumService,
            private rPRsharedService: RPRsharedService
        ) {
    }

    public dataStudy = new ContextualString();
    public companyRankedBy = new ContextualString();
    public dataStudyoptions: Suggestion<string>[] = [];
    public companyRankedByoptions: Suggestion<string>[] = [];
    public tblHeadrers!: string[];
    public cocInputs!: CocInputValues;
    public arraydecilesGrouping!: DecilesPortfolio[];
    public arraydecilesData!: DecilesPortfolio[];
    public arraydecilesSplit!: DecilesPortfolio[];
    public numberFormatUtil!: NumberFormatUtil;
    public arrayportfolios!: Portfolio[];
    public arraySpecifiTypePortfolio!: DecilesPortfolio[];
    public arraySupplySpecifiTypePortfolio!: RiskPremiumSizeStudyPortfolio[];
    public arrayHFRSZscore!: HighFinancialRiskStudy[];
    public sizePremiumResourcesList!: CocInputResourcesList;
    public sizePremiumValuationDate!: string;
    public arrySizePremiumResourcesData = sizePremiumResourcesData;
    public lowEndBreakPoint!: string;
    public highEndBreakPoint!: string;
    public exportFileName = 'SP-CRSP'
    public isSizestudySupplemetryData!: Boolean;
    public SupplymentrycompanyRankedBy = new ContextualString();
    public isTenXandYExist !: boolean;
    public tabIndex!: number;
    public isineCoc!: Boolean


    public isSupplemetryData: boolean = false;
    public commonSupplyHeaders!: [] | any;

    public regressionValues!: RegressionValues;
    public crspSupplycharacterHeader = crspDecileCharacter;
    public rprspSupplycharacterHeader = spRPRCharacter;
    public decileTitle = 'Characteristics of Companies that Comprise 10w, 10x, 10y, and 10z';
    public rprTitle = 'Size Characteristics of Companies that Comprise Portfolio’s 23, 24, and 25';
    public decilCrspPortfolio = 'Characteristics of 10w, 10x, 10y, 10z Percentile Portfolios'

    public headresData = {
        'CRSP Deciles Size Study': ['Decile', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Size Premium'],
        'Risk Premium Report Size Study': ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Size Premium'],
        'High-Financial-Risk Study': ['Z-Score Portfolio', 'Zone', 'Industry', 'Size Premium']
    } as any;


    public employeeHeaders = ['Portfolio', 'Low End Breakpoint', 'High End Breakpoint', 'Size Premium'];

    public supplementaryDataHeaders = {
        'CRSP Deciles Size Study': ['Decile', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Number of Companies', 'Arithmetic Return', 'Geometric Return', 'Standard Deviation of Returns', 'OLS Beta', 'Sum Beta', 'Total Decile Market Capitalization ($T)'],

        'High-Financial-Risk Study': ['Z-Score Portfolio', 'Zone', 'Industry', 'Artihmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta(Since 1963)', 'Avg. Debt/MVIC', 'Avg. Debt/Equity', 'Avg. Operating Margin']
    } as any;

    public companyRankedData = {
        'CRSP Deciles Size Study': 'Market Value of Common Equity',
        'Risk Premium Report Size Study': RiskPremiumReportSizeStudy,
        'High-Financial-Risk Study': 'z-score',
    } as any;

    public showDecileSupplementaryData: boolean = false;
    public showHFRSupplementaryData: boolean = false;

    ngOnInit(): void {
        this.isineCoc = (this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.IneAndCoc || this.sharedCocInputService.usrPerms.SubsType == EnumSubsType.Ine) && this.sharedCocInputService.cocInputValues.investorPerspectiveregion != "United States";
        this.loadSizePremiumComponent();
    }

    public dataStudyConfig: CommonInputConfig = {
        name: 'Data Study',
        placeholder: 'Select Data',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true,
    };

    public companyRankedByConfig: CommonInputConfig = {
        name: 'Companies Ranked By',
        placeholder: 'Select Company ',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };

    public suplyCompanyRankedByConfig: CommonInputConfig = {
        name: '',
        placeholder: 'Select Company ',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: false,
        required: true,
        readonly: true
    };



    public loadSizePremiumComponent() {
        this.isineCoc ? this.setNoData() : this.setDataOptions();

    }

    public setNoData() {
        this.tblHeadrers = this.headresData[DataStudy.CRSPDecilesSizeStudy];
        this.dataStudyoptions = [];
        this.companyRankedByoptions = [];
        this.dataStudy.value = "N/A";
        this.companyRankedBy.value = "N/A"
    }

    public setDataOptions() {
        this.dataStudyoptions = [];
        const dataOptions = [DataStudy.CRSPDecilesSizeStudy, DataStudy.RiskPremiumReportSizeStudy, DataStudy.HighFinancialRiskStudy];
        dataOptions.forEach((item) => {
            this.dataStudyoptions.push({
                name: item,
                value: item,
                type: ComboboxDataType.String,
                disabled: false
            });
        });
        this.tblHeadrers = this.headresData[DataStudy.CRSPDecilesSizeStudy];
        this.dataStudy.value = DataStudy.CRSPDecilesSizeStudy
        this.setCompanyData(DataStudy.CRSPDecilesSizeStudy);

    }

    public supplementaryCmpnyRankedBy = new ContextualString();


    public onClickViewSupplementryData() {


        if (this.dataStudy.value == DataStudy.CRSPDecilesSizeStudy) {
            this.tabIndex = 0;
            this.commonSupplyHeaders = this.supplementaryDataHeaders[DataStudy.CRSPDecilesSizeStudy];
            this.supplementaryCmpnyRankedBy.value = 'Market Value of Common Equity';
            this.suplyCompanyRankedByConfig.disabled = true;
            this.getCrspCharacteristicData(this.sharedCocInputService.cocInputValues.valuationDate);
        }
        if (this.dataStudy.value == DataStudy.HighFinancialRiskStudy) {
            this.commonSupplyHeaders = this.supplementaryDataHeaders[DataStudy.HighFinancialRiskStudy];
            this.supplementaryCmpnyRankedBy.value = 'z-score';
            this.suplyCompanyRankedByConfig.disabled = true;
            this.isSupplemetryData = true;
        }
        if (this.dataStudy.value == DataStudy.RiskPremiumReportSizeStudy) {
            this.tabIndex = 0;
            this.commonSupplyHeaders = this.sharedCocInputService.RPRSSsupplyDataHeaders
            this.isSizestudySupplemetryData = true;
            this.supplementaryCmpnyRankedBy.value = 'Market Value of Common Equity'
            this.getRFRcharacteristicData(this.sharedCocInputService.cocInputValues.valuationDate);
        }
    }


    public getCrspCharacteristicData(dataAsof: string) {
        this.spinner.begin();
        this.sizePremiumService.getCharacteristicData(dataAsof).subscribe((data: CrspCharacteristicsWithPortfolioData) => {
            this.sizePremiumService.crspCharacteristicData = data.CrspCharacteristics;
            this.sizePremiumService.crspCharacteristicPortfolioData = data.CrspCharacteristicsPortfolio
            if (data.CrspCharacteristics?.find(s => s.Decile == '10w')?.Decile) {
                this.isTenXandYExist = false;
            } else {
                this.isTenXandYExist = true;
            }
            this.spinner.end();
            this.isSupplemetryData = true;

        });
    }

    public getRFRcharacteristicData(dataAsof: string) {
        this.spinner.begin();
        this.rPRsharedService.getRFRCharacteristicsData(dataAsof).subscribe((data: RiskPremiumCharacteristicsData) => {
            this.spinner.end();
            data = this.mapRfrCharacteristicData(data);
            this.rPRsharedService.riskPremiumCharacteristicsData = data;
            this.isSupplemetryData = true;
        });
    }


    public mapRfrCharacteristicData(data: any) {
        Object.keys(data).filter(s => s != 'dataAsOf').forEach(key => {
            if (data[key] !== null) {
                data[key] = Object.entries(data[key])
            } else {
                data[key] = null
            }
        });
        return data;
    }

    public setCompanyData(dataStudy: string) {
        this.tblHeadrers = this.headresData[dataStudy];
        this.companyRankedByoptions = [];
        const companyOptions = this.companyRankedData[dataStudy];

        if (dataStudy === DataStudy.RiskPremiumReportSizeStudy) {
            Object.keys(companyOptions).forEach((key => {
                this.companyRankedByoptions.push({
                    name: key,
                    value: key,
                    type: ComboboxDataType.String,
                });
            }))
            this.companyRankedByConfig.disabled = false;
            this.companyRankedBy.value = 'Market Value of Common Equity'
            this.onCompanyRankedByChnage(this.companyRankedBy)
        } else {
            this.companyRankedByConfig.disabled = true;
            this.companyRankedBy.value = companyOptions;
        }
    }


    public onCompanyRankedByChnage(companyRankedBy: any) {
        let type = RiskPremiumReportSizeStudy[companyRankedBy.value] as InputType
        this.spinner.begin();
        this.arraySpecifiTypePortfolio = this.sharedCocInputService.arrayportfolios.filter(item => item.InputType === InputType[type]).map(port => {
            return {
                id: "" + port.Id,
                name: port.Name,
                lowBreakPoint: port.Lower,
                highBreakPoint: port.Upper,
                sizePremium: (port.Result * 100),
            } as DecilesPortfolio
        });
        this.regressionValues = this.sharedCocInputService.rprRegressionAppendixB[type];
        this.spinner.end();
    }

    public exportToExcel(component: ExcelExportComponent) {

        let enumdataStudy = enumExcelExportSheets.SPHighFinancialRiskStudy
        this.exportFileName = 'SP-HFR-data'
        if (this.dataStudy.value == DataStudy.RiskPremiumReportSizeStudy) {
            this.exportFileName = 'SP-RPRS-data'
            enumdataStudy = enumExcelExportSheets.SPRiskPremiumReportSizeStudy;

        }
        if (this.dataStudy.value == DataStudy.CRSPDecilesSizeStudy) {
            this.exportFileName = 'SP-CRSP-data'
            enumdataStudy = enumExcelExportSheets.CRSPDecilesSizeStudy;
        }
        this.exportService.generateWorkBook(component, enumdataStudy);
    }

    public onChangeSupplyComapanyRankedBy(companyRankedBy: any) {
        let type = RiskPremiumReportSizeStudy[companyRankedBy.value] as InputType;
        if (type) {
            this.mapRPSSsupplymentryData(type);
        }
    }

    public mapRPSSsupplymentryData(type: InputType) {
        this.arraySupplySpecifiTypePortfolio = this.sharedCocInputService.arraysupplyDecilPortfolio.filter(item => item.InputType === type);
    }
}

