import { Component, Input, OnInit } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { TearSheetService } from './data/tear-sheet.service';
import { TrendsOverTimeModalComponent } from './modals/trends-over-time-modal.component';
import { MetricTable } from './models/metric-table.model';
import { FlatenMtericCategory, MetricDetails } from './models/metric-category.model';

@Component({
    selector: 'industry-data-table',
    templateUrl: './industry-data-table.component.html'
})
export class IndustryDataTableComponent {
    @Input() public metricCategory!: FlatenMtericCategory;
    @Input() public trendsOverTimeColumn!: boolean;

    constructor(
        private primeManager: PrimeManager,
        private spinner: Spinner,
        private tearSheetService: TearSheetService,
        private dataStore: DataStore
    ) { }



    public openTrendsOverTimeModal(column: string, metric: MetricDetails): void {

        // TODO move logic into a utility component or potentially a service
        const isAverage = column === '5-Yr Avg' ? true : false;
        const industryData = this.tearSheetService.getTrendsOverTimeIndustry();
        const dataRequests = industryData.map((industry) => {
            return {
                IndustryCodeId: industry.IndustryCodeId,
                MetricId: metric.metricId,
                CurrencyCode: industry.CurrencyCode,
                Area: industry.Area,
                SicId: industry.SicId,
                GicId: industry.GicId,
                ValuationDate: moment(industry.ValuationDate).format('LL')
            };
        });
        const request = this.dataStore.retrieveTrendsOverTime(dataRequests);

        this.spinner.while(request);

        request.once(() => {
            const trendsOverTimeData = this.dataStore.trendsOverTimeData;
            trendsOverTimeData.onceDefined((data) => {
                if (isAverage) {
                    data.forEach((x) => {
                        x.forEach((y) => {
                            y.Metric.MedianLatest = y.Metric.MedianAverage;
                            y.Metric.SICCompositeLatest = y.Metric.SICCompositeAverage;
                            y.Metric.LargeCompositeLatest = y.Metric.LargeCompositeAverage;
                            y.Metric.HighFinancialRiskLatest = y.Metric.HighFinancialRiskAverage;
                            y.Metric.SmallCompositeLatest = y.Metric.SmallCompositeAverage;
                        });
                    });
                }
                this.primeManager.openDialog(TrendsOverTimeModalComponent, {
                    trendsOverTimeMetric: {
                        metricId: metric.metricId,
                        metricName: metric.metricName,
                        metricCategory: metric.metricCategory
                    },
                    metricCatogeryName: metric.metricCategoryName,
                    trendsOverTimeData: data,
                    trendsOverTimeIndustries: industryData
                });
            });
        });
    }

    public getMtericsLength(metricDetails: MetricDetails[], metricName: string): number {
        return metricDetails.filter(s => s.name === metricName)?.length;
    }
}
