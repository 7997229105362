import { Injectable } from '@angular/core';
import { CocInputValues } from '../models/cocInputs';
import { BetaLeverage, RiskFreeRateERP, RiskPremiumDataStudy, RiskPremiumReportRiskStudy, RiskPremiumReportSizeStudy, zscoredata, zscoredataRPORFR } from '../cocViewInputs';
import { BetaIndustryData, DataValues, DecilesPortfolio, HighFinancialRiskStudy, LeveredPortfolio, RiskPremiumPortfolio, RiskPremiumSizeStudyPortfolio, USDebtBetasUi, UnleveredPortfolio, BetaIndustryInfo, RiskFreeRateOverTime } from '../models/cocInputsTabs';
import { Spinner } from '@concurrency/angular/dist/spinner/spinner';
import { Observable, Subject, combineLatest } from 'rxjs';
import { IntlRfClient } from 'src/app/_api/clients/intlRf.client';
import { IntlErpClient } from 'src/app/_api/clients/intlErp.client';
import { map, takeUntil } from 'rxjs/operators';
import { SuggestionResponse } from 'src/app/_api/responses/suggestion.response';
import { DatePipe } from '@angular/common';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { RiskClient } from 'src/app/_api/clients/risk.client';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { Portfolio } from 'src/app/_api/responses/portfolio.response';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { ResourceFileType } from 'src/app/_api/enums/resource-file-type';
import { ResourcesLibraryComponent } from 'src/app/home/resources-library/resources-library.component';
import { ContainerTypeEnum } from 'src/app/home/resources-library/enum/container-type.enum';
import { CocInputResourcesList } from '../coc-input-resources-list.model';
import { USIndustryClient } from 'src/app/_api/clients/usindustry.client';
import { IndustryBetas, USDebtBetas } from 'src/app/_api/responses/us-industry.response';
import { ContextualString, Util } from '@concurrency/core';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { InputType } from 'src/app/_api/responses/input.response';
import { RegressionValues } from 'src/app/_api/responses/RprRegressionData.response';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { RFTrendsOverTime } from 'src/app/_api/responses/rf-trends-over-time.reponse';
import * as moment from 'moment';
import { ErpSupplymetryDetailData } from 'src/app/_api/responses/erp.supplymentary.data';
import { UsIndustryDataClient } from 'src/app/_api/clients/usIndustryData.client';
import { USCompanyClient } from 'src/app/_api/clients/uscompany.client';
import { BetaCompanyData } from 'src/app/estimate/studies/_editors/beta-editor-company-list.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { EnumSubsType, UserSubscriptionTypes } from '../models/userSubscriptionTypes';
import { IndustryClient } from 'src/app/_api/clients/industry.client';
import { ViewInputBetasByRegion } from 'src/app/_api/responses/industry.response';
import { IntlData } from 'src/app/_api/responses/intl-data.response';
import { IntlIndustryClient } from 'src/app/_api/clients/intlIndustry.client';
import { CompanyClient } from 'src/app/_api/clients/company.client';
import { DebtBetaTrendsOverTime } from '../models/debtBetaTrendsOverTime';
import { IntlDebtBetasData, IntlDebtBetasDataByRegionAndCurrency } from 'src/app/_api/responses/debt-betas-by-region-currency.response';

@Injectable({
    providedIn: 'root'
})

export class CocInputsSharedService {

    //#region ARFROERP
    public rf!: DataValues[];
    public erp!: DataValues[];
    public arryRiskFreeRateOverERPResources = RiskFreeRateERP;
    public rfrErpResourcesList!: CocInputResourcesList
    public sizePremiumResourcesList!: CocInputResourcesList
    public riskPremiumOverRfrResourcesList!: CocInputResourcesList
    public industryBetasResourcesList!: CocInputResourcesList
    public industryBetasFormulasList!: CocInputResourcesList
    public countryRiskPremiumResourceList!: CocInputResourcesList


    public notifier = new Subject();
    public rfrSuggestions!: IntlData[];
    public erpSuggestions!: any[];
    public erpSuggestionsIntl!: IntlData[];
    public spot10YearYields!: number[];
    public spot20YearYields!: number[];
    public spot30YearYields!: number[];
    public spot15YearYields!: number[];
    public longTermYields!: number[];
    public dpRecommendedRfrs!: any[];
    public dataAsOfDates!: string[];
    public industryBetasResponse!: IndustryBetas[];
    public usDebtsBetasResponse!: USDebtBetas[];
    public erpSupplymentryData!: ErpSupplymetryDetailData
    public intlIndustryBetasResponse!: ViewInputBetasByRegion[];
    public debtBetasByRegionCurrencyResponse!: IntlDebtBetasDataByRegionAndCurrency[];
    public debtBetasReponse!: IntlDebtBetasData[];
    public debtBetasValuationDate!: string;

    //using common array to hold debt beta values for different country and geo coverage selection, at a time
    public CommonDebtBetaArrayForUI!: USDebtBetasUi[];
    private DebtBetasSelectedRegion!: string;
    //#endregion


    //#region  SizePremium
    public arraydecilesGrouping!: DecilesPortfolio[];
    public arraydecilesData!: DecilesPortfolio[];
    public arraydecilesSplit!: DecilesPortfolio[];
    public sizePremiumValuationDate!: string;
    public industryBetasValuationDate!: string;
    public usDebtBetasValuationDate!: string;
    public rporfrDataAsOf!: string;
    public arrayHFRSZscore!: HighFinancialRiskStudy[];
    public arrayportfolios!: Portfolio[];
    public arraysupplyDecilPortfolio!: RiskPremiumSizeStudyPortfolio[];
    public rprRegressionAppendixB!: [] | any;
    //#endregion


    //#region  RPORFR

    public arrayportfoliosRPORFR!: Portfolio[];
    public arrayLeveredPortfolios!: Portfolio[];
    public arrayUnleveredPortfolios!: Portfolio[];
    public arrayRiskStudyPortfollios!: Portfolio[];
    public arraySpecifiTypePortfolio!: DecilesPortfolio[];
    public arrayHFRSZscoreRPORFR!: HighFinancialRiskStudy[];
    public rprRegressionAppendixA!: [] | any;
    public rprRegressionAppendixC!: [] | any;
    public rprRegressionAppendixD!: [] | any;
    public arraySuppRiskStudyPortfollios!: [] | any

    public leveredBetas!: BetaIndustryData[];
    public unleveredBetas!: BetaIndustryData[];
    public betaIndustryIdList!: BetaIndustryInfo[];
    public gicsData!: boolean;
    public sicsData!: boolean;
    public gicsDate: string = "2020-09-30";
    public isBetaDisplayed!: string;
    public erpValueDisplayed!: string;
    //public irpBetasLoaded!: boolean;
    public betaIndustry = new ContextualString();
    public industryDataOptions: Suggestion<string>[] = [];
    public irpDataOptions: Suggestion<string>[] = [];
    public tabHeaders!: string;
    public irptabHeaders!: string;
    public DebtBetasTabHeading!: string;
    public tableHeaders!: string[];
    public irpTableHeaders!: string[];
    public DebtBetasTableHeaders!: string[];
    public initialIndustryRiskPremium!: string

    public betaIndustryGeoCoverage = new ContextualString();
    public betaIndustryGeoCoverageOptions: Suggestion<string>[] = [];
    public betaIndustryGeoCoverageConfig: CommonInputConfig = {
        name: 'Geographic Coverage',
        placeholder: 'Geographic Coverage',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };
    public betaIndustryGeoCoverageCurrency = new ContextualString();

    public _ValuationDateChanged = new Subject<Boolean>();
    public dataPoints = {
        "Spot 10-year Yield (United States)": "Spot 10-year Treasury Yield",
        "Spot 20-year Yield (United States)": "Spot 20-year Treasury Yield",
        "Spot 30-year Yield (United States)": "Spot 30-year Treasury Yield",
        "Normalized Risk-free Rate (United States)": "Kroll Normalized Risk-free Rate"
    } as any


    public getFinalDecile: any = {
        "10A": "10A",
        "10W": "10w",
        "10X": "10x",
        "10B": "10B",
        "10Y": "10y",
        "10Z": "10z"
    }

    public betaHeadresData = {
        'Levered': ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'],
        'Unlevered': ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'],
        'ERP used in RPi': ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'],
        'DebtBetas': ['Moodys Ratings', 'Value']
    } as any;
    public industryRiskPremia = new ContextualString();
    public industryRiskPremiaBetas!: BetaIndustryData[];
    public usDebtBetaValues!: USDebtBetasUi[];
    public leverageBeta = new ContextualString();
    public checkIsBetaUpdated!: boolean;
    public creditRatingsSort!: string[];

    checkIsBetaUpdated$ = new Subject<boolean>();
    checkIsRPOVRFRUpdated$ = new Subject<boolean>();
    checkIsDebtBetasUpdated$ = new Subject<boolean>();
    checkIsIntlBetasUpdated$ = new Subject<boolean>();


    public arrayRPRSSLeveredTypePortfoio!: LeveredPortfolio[];
    public arrayRPRSSUnleveredTypePortfolio!: UnleveredPortfolio[];
    public arrayRPRRSLeveredTypePortfolio!: LeveredPortfolio[];
    public arrayRPRRSUnleveredTypePortfolio!: UnleveredPortfolio[];
    public arrayRPSTypePortfolio!: RiskPremiumPortfolio[];
    public arraySupplyRPRSSdata!: RiskPremiumSizeStudyPortfolio[];
    public arrayRPRTypePortfolio!: RiskPremiumPortfolio[];
    public riskPremiumOverRFRValuationDate!: string;
    public riskStudyRegressionValues!: RegressionValues;
    public sizeStudyLeveredRegressionValues!: RegressionValues;
    public sizeStudyUnleveredRegressionValues!: RegressionValues;
    public dataStudyoptions: Suggestion<string>[] = [];
    public companyRankedByoptions: Suggestion<string>[] = [];
    public tblHeadrers!: string[];
    public riskPremiumDataStudy = new ContextualString();
    public riskFreeRateCompanyRankedBy = new ContextualString();
    public isviewSupplymentrylinks!: boolean;
    public showCrpRvData!: boolean;
    public usrPerms: UserSubscriptionTypes = {} as UserSubscriptionTypes;

    public riskFreeRateHeadresData = {
        'Risk Premium Report Size Study': ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Levered Risk Premium over the Risk-free Rate', 'Unlevered Risk Premium over the Risk-free Rate'],
        'Risk Premium Report Risk Study': ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Levered Risk Premium over the Risk-free Rate'],
        'High-Financial-Risk Study': ['Z-Score Portfolio', 'Zone', 'Industry', 'Risk Premium over the Risk-free Rate']
    } as any;

    public riskFreeRateCompanyRankedData = {
        'Risk Premium Report Size Study': RiskPremiumReportSizeStudy,
        'Risk Premium Report Risk Study': RiskPremiumReportRiskStudy,
        'High-Financial-Risk Study': 'z-score',
    } as any;

    public riskFreeRateCompanyRankedByConfig: CommonInputConfig = {
        name: 'Companies Ranked By',
        placeholder: 'Select Company ',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };

    public RfrTrendsOverTimeData!: RFTrendsOverTime[];

    public CRSP10thDecileOrder: { [key: string]: number } = {
        "10A": 1,
        "10W": 2,
        "10X": 3,
        "10B": 4,
        "10Y": 5,
        "10Z": 6
    };
    public RPRSSsupplyDataHeaders = ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Avg. Portfolio Size ($M)', 'Number of companies', 'Arithmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta (Since 1963)', 'Avg. \n Unleverd Beta', 'Avg. \n Debt/MVIC', 'Avg. \n Debt/Equity', 'Avg.Operating Margin', 'Avg.CV of Operating Margin', 'Avg.CV of ROE'];

    public BetaTypeOptions = { Fibs: 'Full-Information Beta', Others: 'OLS/Sum/Vasicek/Blume/Peer Betas' };
    public BetaTypeOptions_1 = ['Full-Information Beta', 'OLS/Sum/Vasicek/Blume/Peer Betas'];
    public betaTypeOptionSelected!: string;
    public betaIndustryIdSelected!: number;
    public FibsCompanies!: string[];
    public OtherCompanies!: string[];
    public BetaComapniesInfo!: string[];
    public isIneCoc!: boolean;
    public IndustryBetasDataAvailable: boolean = false;
    public ShowUSDebtBetas: boolean = false;

    //#endregion
    constructor(
        private spinner: Spinner,
        private erpSpinner: Spinner,
        private betaSpinner: Spinner,
        private intlRfClinet: IntlRfClient,
        private intlErpClient: IntlErpClient,
        private measureClient: MeasureClient,
        private riskClient: RiskClient,
        private filesClient: FilesClient,
        private usIndustryClient: USIndustryClient,
        private erpintlClient: IntlErpClient,
        private usCompanyClient: USCompanyClient,
        private industryClient: IndustryClient,
        private intlIndustryClient: IntlIndustryClient,
        private companyClient: CompanyClient

    ) {

    }


    cocInputValues!: CocInputValues;
    public CocInputResourceFiles!: string;


    public setCocInputValues(inutValues: CocInputValues) {
        this.cocInputValues = inutValues;
        this.viewSupplymetryDataLinks();
        this.showCRPandRVData();
    }

    public getCocInputValues(): CocInputValues {
        return this.cocInputValues;
    }

    public showCRPandRVData() {
        let CrpRvDataLimitDate = new Date('2014-03-31');
        let valuationDate = new Date(this.cocInputValues.valuationDate);
        if (valuationDate >= CrpRvDataLimitDate) {
            this.showCrpRvData = true;
        } else {
            this.showCrpRvData = false;
        }
    }

    public viewSupplymetryDataLinks() {
        let date = new Date('2013-12-31');
        let valuationDate = new Date(this.cocInputValues.valuationDate)
        //valuationDate.setHours(0, 0, 0, 0)
        if (valuationDate >= date) {
            this.isviewSupplymentrylinks = true;
        } else {
            this.isviewSupplymentrylinks = false;
        }

    }

    public getInitialTabsData() {
        this.isIneCoc = (this.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInputValues.investorPerspectiveregion != "United States" || this.usrPerms.SubsType == EnumSubsType.Ine);
        this.gicsData = this.cocInputValues.valuationDate >= this.gicsDate;
        this.getRprRegressionValues();

    }

    public getAllTabsData() {
        this.getErpSupplyData(this.cocInputValues.valuationDate)
        this.getRiskPremiumORFRReportStudyRPORFR();
        this.getHighFinancialRiskStudyZScoreRPORFR();
        this.getDeCRSPDecilesSizeStudy();
        this.getRiskPremiumReportStudy();
        this.getHighFinancialRiskStudyZScore();
        this.getRfrAndErpValues();
        this.getResourceFiles();
        this.getRfTrendsOverTimeData();

    }

    public getRfTrendsOverTimeData() {
        this.spinner.begin();
        const obj: RiskFreeRateOverTime = {
            CountryId: this.cocInputValues.investorCountryId,
            countryName: this.cocInputValues.investorPerspectiveregion,
            DataAsOf: this.cocInputValues.valuationDate
        }
        this.intlRfClinet.GetRfrsForToT(obj).subscribe(data => {
            this.RfrTrendsOverTimeData = data;

            this.spot10YearYields = this.RfrTrendsOverTimeData.map(data => data.Spot10YearYield * 100);
            this.spot20YearYields = this.RfrTrendsOverTimeData.map(data => data.Spot20YearYield * 100);
            this.spot30YearYields = this.RfrTrendsOverTimeData.map(data => data.Spot30YearYield * 100);

            if (this.cocInputValues.investorPerspectiveregion != "United States") {
                this.spot15YearYields = this.RfrTrendsOverTimeData.map(data => data.Spot15YearYield * 100);
                this.longTermYields = this.RfrTrendsOverTimeData.map(data => data.LongTermYield * 100);
            }


            this.dpRecommendedRfrs = this.RfrTrendsOverTimeData.map(data => {
                if (data.DpRecommendedRfr <= 0) {
                    return ''; //setting this to '' so that highcharts don't show it as 0
                } else {
                    return data.DpRecommendedRfr * 100;
                }
            });
            this.dataAsOfDates = this.RfrTrendsOverTimeData.map(data => moment(data.DataAsOf).format('MM/DD/YYYY'));
            this.spinner.end();
        }, error => {
            this.spot10YearYields = [];
            this.spot20YearYields = [];
            this.spot30YearYields = [];
            this.dpRecommendedRfrs = [];
            this.dataAsOfDates = [];
            this.spinner.end();
        });
    }

    public getResourceFiles() {
        this.spinner.begin();
        this.filesClient.getResources(ResourceFileType.CocInputs).subscribe(data => {
            this.rfrErpResourcesList = JSON.parse(data).rfrErp;
            this.sizePremiumResourcesList = JSON.parse(data).sizePremium;
            this.riskPremiumOverRfrResourcesList = JSON.parse(data).riskPremiumOverRfr;
            this.industryBetasResourcesList = JSON.parse(data).industryBetasResources;
            this.industryBetasFormulasList = JSON.parse(data).industryBetasFormulas;
            this.countryRiskPremiumResourceList = JSON.parse(data).countryRPandRealtiveV;
        });
        this.spinner.end();
    }

    public getBetasTabData() {

        this.intlIndustryBetasResponse = [];
        this.checkIsBetaUpdated$.subscribe((value) => {
            if (value === true) {
                this.setBetaDataOptions();
            }
        });

        var regions: string[] = [];
        if (this.betaIndustryGeoCoverageOptions.length > 0) {
            regions = this.betaIndustryGeoCoverageOptions.map(region => region.value);
        } else if (this.betaIndustryGeoCoverage.value !== null) {
            regions.push(this.betaIndustryGeoCoverage.value);
        }
        const currency = this.betaIndustryGeoCoverageCurrency.value ?? "";

        if (regions.length > 0 && !Util.isNullOrEmpty(currency)) {
            this.betaSpinner.begin();
            this.intlIndustryClient.getIndustryBetas(regions, currency, this.cocInputValues.valuationDate).onceDefined(result => {
                this.intlIndustryBetasResponse = result;
                this.checkIsBetaUpdated$.next(true);
            });
            this.betaSpinner.end();
        }
    }


    private getDebtBetasData() {

        this.checkIsIntlBetasUpdated$.subscribe(value => {
            if (value === true) {
                if (this.betaIndustryGeoCoverage.value) {
                    this.loadDebtBetasDataByRegionSelected(this.betaIndustryGeoCoverage.value);
                }
                this.checkIsIntlBetasUpdated$.next(false);
            }
        });

        var regions: string[] = [];
        if (this.betaIndustryGeoCoverageOptions.length > 0) {
            regions = this.betaIndustryGeoCoverageOptions.map(region => region.value);
        } else if (this.betaIndustryGeoCoverage.value !== null) {
            regions.push(this.betaIndustryGeoCoverage.value);
        }
        const currency = this.betaIndustryGeoCoverageCurrency.value ?? "";

        if (regions.length > 0 && !Util.isNullOrEmpty(currency)) {
            this.spinner.begin();
            this.intlIndustryClient.getDebtBetasData(regions, currency, this.cocInputValues.valuationDate).onceDefined(result => {
                this.debtBetasByRegionCurrencyResponse = result;
                this.checkIsIntlBetasUpdated$.next(true);
            });
            this.spinner.end();
        }
    }

    private setDebtBetaAsPerGeoRegion() {

    }
    //#region RFROERP

    public getRfrAndErpValues() {

        let apiCallsBasedOnSubscription = [this.intlRfClinet.read(this.cocInputValues.investorCountryId, this.cocInputValues.valuationDate), this.intlErpClient.read(this.cocInputValues.investorCountryId, this.cocInputValues.valuationDate), this.filesClient.getResources(ResourceFileType.CocInputs)];

        if (this.usrPerms.SubsType == EnumSubsType.Coc) {
            apiCallsBasedOnSubscription[1] = this.intlErpClient.GetUsErps(this.cocInputValues.valuationDate) as any;
        } else if (this.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInputValues.investorPerspectiveregion == "United States") {
            apiCallsBasedOnSubscription = [this.intlRfClinet.read(this.cocInputValues.investorCountryId, this.cocInputValues.valuationDate), this.intlErpClient.read(this.cocInputValues.investorCountryId, this.cocInputValues.valuationDate), this.intlErpClient.GetUsErps(this.cocInputValues.valuationDate) as any, this.filesClient.getResources(ResourceFileType.CocInputs)];
        }

        this.erpSpinner.begin()
        combineLatest(apiCallsBasedOnSubscription).pipe(takeUntil(this.notifier), map((resp: any) => {
            if (this.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInputValues.investorPerspectiveregion == "United States") {
                return {
                    rfdata: resp[0],
                    erpintl: resp[1],
                    erpUs: resp[2],
                    resource: resp[3]
                }
            } else {
                return {
                    rfdata: resp[0],
                    erpUsorIntl: resp[1],
                    resource: resp[3]
                }
            }
        })).onceDefined((rfrValues) => {
            if (this.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInputValues.investorPerspectiveregion == "United States") {
                this.rfrSuggestions = rfrValues.rfdata;
                this.erpSuggestions = rfrValues.erpUs;
                this.erpSuggestionsIntl = rfrValues.erpintl;

            } else {

                this.rfrSuggestions = rfrValues.rfdata;
                this.erpSuggestions = rfrValues.erpUsorIntl;
            }
            this.assignRfrErpValues();

        }, (() => { }), () => this.spinner.end());



    }

    public assignRfrErpValues(): void {

        var erpdata: any;

        // let rf_suggestionTypes = [
        //     'Spot10YieldRiskFreeRate', 'Spot20YieldRiskFreeRate', 'Spot30YieldRiskFreeRate', 'RecommendedRiskFreeRate'
        // ];
        let erp_suggestionTypes = [
            'RecommendedEquityRiskPremium', 'SupplySideEquityRiskPremium', 'HistoricalEquityRiskPremium'
        ];

        this.rf = this.rfrSuggestions
            .map(x => {
                return {
                    datapoint: this.usrPerms.SubsType != EnumSubsType.Coc ? x.Label : this.assigneYieldforUs(x.Label),
                    value: x.Value,
                    dataasof: new DatePipe('en-US').transform(x.DataAsOf, 'MM/dd/yyyy'),
                } as DataValues
            });


        const filetrerp = this.erpSuggestions.filter(erp => erp_suggestionTypes.find(d => d == erp.SuggestionType));
        if (this.usrPerms.SubsType == EnumSubsType.Coc) {
            erpdata = this.mapSuggestionsTypeToIntlData(filetrerp);
        } else if (this.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInputValues.investorPerspectiveregion == "United States") {
            erpdata = [...this.mapSuggestionsTypeToIntlData(filetrerp), ...this.erpSuggestionsIntl.filter(d => d.Label != "United States Kroll Recommended ERP")]
        } else {
            erpdata = this.erpSuggestions;
        }


        this.erp = erpdata.map((x: any) => {
            return {
                datapoint: x.Label,
                value: x.Value,
                dataasof: new DatePipe('en-US').transform(x.DataAsOf, 'MM/dd/yyyy'),

            } as DataValues

        });

        if (this.erp.length == 0) {
            this.makeIrpDataOptionsNA();
        }

        this.erpSpinner.end();
        this.initialIndustryRiskPremium = `${this.erp[0]?.value}% - ${this.erp[0]?.datapoint}`
        this.getBetasTabData();
        this.getDebtBetasData();

    }


    private mapSuggestionsTypeToIntlData(data: SuggestionResponse[]): any[] {
        let intlMapedData: any;

        try {
            intlMapedData = data.map((obj) => {
                return {
                    Value: obj.Value,
                    Label: obj.Source,
                    DataAsOf: obj.DataAsOf,
                } as IntlData
            });

        } catch (error) {
            console.log(error)
        }
        return intlMapedData;



    }


    public assigneYieldforUs(point: string): string {
        var tempdatapoint = this.dataPoints[point] ?? point;
        return tempdatapoint;
    }

    //#endregion

    //#region  SizePremium
    public getDeCRSPDecilesSizeStudy() {
        this.spinner.begin();
        this.measureClient.getDeciles(this.cocInputValues.valuationDate).onceDefined((result) => {
            this.arraydecilesGrouping = result.filter((z) => z.Type === 'Decile').map(deci => {
                return {
                    id: deci.Id ? deci.Id : deci.Name,
                    name: deci.Name,
                    dataAsOf: deci.DataAsOf,
                    lowBreakPoint: deci.SmallestCap,
                    highBreakPoint: deci.LargestCap,
                    sizePremium: (deci.CapM),
                    geometricReturn: deci.GeometricReturn,
                    numberOfCompanies: deci.NumberOfCompanies,
                    arithmeticMeanReturn: deci.ArithmeticMeanReturn,
                    standardDeviationOfReturns: deci.StandardDeviationOfReturns,
                    olsBeta: deci.OlsBeta,
                    sumBeta: deci.SumBeta,
                    totalDecileMarketCapitalization: deci.TotalDecileMarketCapitalization
                } as DecilesPortfolio
            });
            this.arraydecilesData = result.filter((z) => z.Type === 'Grouping').map(deci => {
                return {
                    id: deci.Id ? deci.Id : deci.Name,
                    name: deci.Name,
                    dataAsOf: deci.DataAsOf,
                    lowBreakPoint: deci.SmallestCap,
                    highBreakPoint: deci.LargestCap,
                    sizePremium: (deci.CapM),
                    geometricReturn: deci.GeometricReturn,
                    numberOfCompanies: deci.NumberOfCompanies,
                    arithmeticMeanReturn: deci.ArithmeticMeanReturn,
                    standardDeviationOfReturns: deci.StandardDeviationOfReturns,
                    olsBeta: deci.OlsBeta,
                    sumBeta: deci.SumBeta,
                    totalDecileMarketCapitalization: deci.TotalDecileMarketCapitalization
                } as DecilesPortfolio
            });
            this.arraydecilesSplit = result.filter((z) => z.Type === 'Split').map(deci => {
                return {
                    id: deci.Id ? deci.Id : deci.Name,
                    name: deci.Name,
                    dataAsOf: deci.DataAsOf,
                    lowBreakPoint: deci.SmallestCap,
                    highBreakPoint: deci.LargestCap,
                    sizePremium: (deci.CapM),
                    geometricReturn: deci.GeometricReturn,
                    numberOfCompanies: deci.NumberOfCompanies != null ? deci.NumberOfCompanies : 0,
                    arithmeticMeanReturn: deci.ArithmeticMeanReturn,
                    standardDeviationOfReturns: deci.StandardDeviationOfReturns,
                    olsBeta: deci.OlsBeta,
                    sumBeta: deci.SumBeta,
                    totalDecileMarketCapitalization: deci.TotalDecileMarketCapitalization != null ? deci.TotalDecileMarketCapitalization : 0
                } as DecilesPortfolio
            }).sort((a, b) => this.CRSP10thDecileOrder[a.id] - this.CRSP10thDecileOrder[b.id]);

            this.arraydecilesSplit.forEach(s => s.id = this.getFinalDecile[s.id]);

            this.sizePremiumValuationDate = this.arraydecilesGrouping[0].dataAsOf ? this.arraydecilesGrouping[0].dataAsOf : new Date().toString();
            // console.log(this.arraydecilesGrouping);

            this.spinner.end();
        })

    }

    public fetchName() {

    }

    public getRiskPremiumReportStudy() {
        this.spinner.begin();
        this.riskClient.getPortfolios(this.cocInputValues.valuationDate).onceDefined((result) => {
            this.arrayportfolios = result.filter(s => s.EquationType === EquationType.RprsCapmSizeStudy);
            this.mapRPSSsupplymentryData();
            this.spinner.end();
        })
    }

    public getHighFinancialRiskStudyZScore() {
        this.arrayHFRSZscore = zscoredata;
        this.spinner.begin();
        this.riskClient.getZscore(this.cocInputValues.valuationDate).onceDefined(result => {
            this.arrayHFRSZscore.forEach(obj => {
                if (obj.industry === 'Manufacturing') {
                    obj.sizePremium = obj.zone === 'Gray Zone' ? result.Manufacturing.Capm.Gray * 100 : result.Manufacturing.Capm.Distress * 100;
                    obj.arithmeticMeanReturn = obj.zone === 'Gray Zone' ? result.Manufacturing.ArithmeticMeanReturn.Gray * 100 : result.Manufacturing.ArithmeticMeanReturn.Distress * 100;
                    obj.geometricMeanReturn = obj.zone === 'Gray Zone' ? result.Manufacturing.GeometicMeanReturn.Gray * 100 : result.Manufacturing.GeometicMeanReturn.Distress * 100;
                    obj.standardDeviationOfReturns = obj.zone === 'Gray Zone' ? result.Manufacturing.StandardDeviation.Gray * 100 : result.Manufacturing.StandardDeviation.Distress * 100;
                    obj.sumBeta = obj.zone === 'Gray Zone' ? result.Manufacturing.Sumbeta.Gray : result.Manufacturing.Sumbeta.Distress;
                    obj.avgDebtToMVIC = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgDebtToMVIC.Gray * 100 : result.Manufacturing.AvgDebtToMVIC.Distress * 100;
                    obj.avgDebtToEquity = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgDebtToMVoE.Gray * 100 : result.Manufacturing.AvgDebtToMVoE.Distress * 100;
                    obj.avgOperatingMargin = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgOperatingMargin.Gray * 100 : result.Manufacturing.AvgOperatingMargin.Distress * 100;

                } else {
                    obj.sizePremium = obj.zone === 'Gray Zone' ? result.Service.Capm.Gray * 100 : result.Service.Capm.Distress * 100;
                    obj.arithmeticMeanReturn = obj.zone === 'Gray Zone' ? result.Service.ArithmeticMeanReturn.Gray * 100 : result.Service.ArithmeticMeanReturn.Distress * 100;
                    obj.geometricMeanReturn = obj.zone === 'Gray Zone' ? result.Service.GeometicMeanReturn.Gray * 100 : result.Service.GeometicMeanReturn.Distress * 100;
                    obj.standardDeviationOfReturns = obj.zone === 'Gray Zone' ? result.Service.StandardDeviation.Gray * 100 : result.Service.StandardDeviation.Distress * 100;
                    obj.sumBeta = obj.zone === 'Gray Zone' ? result.Service.Sumbeta.Gray : result.Service.Sumbeta.Distress;
                    obj.avgDebtToMVIC = obj.zone === 'Gray Zone' ? result.Service.AvgDebtToMVIC.Gray * 100 : result.Service.AvgDebtToMVIC.Distress * 100;
                    obj.avgDebtToEquity = obj.zone === 'Gray Zone' ? result.Service.AvgDebtToMVoE.Gray * 100 : result.Service.AvgDebtToMVoE.Distress * 100;
                    obj.avgOperatingMargin = obj.zone === 'Gray Zone' ? result.Service.AvgOperatingMargin.Gray * 100 : result.Service.AvgOperatingMargin.Distress * 100;
                }
            })
            this.spinner.end();
        })

    }



    //#endregion

    //#region RPORFR

    public getRiskPremiumORFRReportStudyRPORFR() {
        if (!this.isIneCoc) {
            this.checkIsRPOVRFRUpdated$.subscribe((value) => {
                if (value === true) {
                    this.riskFreeRateCompanyRankedBy.value = "";
                    this.riskPremiumDataStudy.value = null;
                    this.setDataOptions();
                }
            });
            this.spinner.begin();
            this.riskClient.getPortfolios(this.cocInputValues.valuationDate).onceDefined((result) => {
                this.arrayportfoliosRPORFR = result.filter(s => s.EquationType !== EquationType.RprsCapmSizeStudy);
                this.arrayLeveredPortfolios = this.arrayportfoliosRPORFR.filter(s => s.EquationType === EquationType.RprsBuildup1Levered);
                this.arrayUnleveredPortfolios = this.arrayportfoliosRPORFR.filter(s => s.EquationType === EquationType.RprsBuildup1Unlevered);
                this.arrayRiskStudyPortfollios = this.arrayportfoliosRPORFR.filter(s => s.EquationType === EquationType.RprsBuildup3);
                this.arraySuppRiskStudyPortfollios = this.arrayportfoliosRPORFR.filter(s => s.EquationType === EquationType.RprsBuildup3);
                this.rporfrDataAsOf = this.arrayLeveredPortfolios[0].DataAsOf ? this.arrayLeveredPortfolios[0].DataAsOf : new Date().toString();
                this.spinner.end();
                this.checkIsRPOVRFRUpdated$.next(true);
            })
        } else {
            this.setNoDataforRPORFR();
        }
    }

    public setNoDataforRPORFR() {
        this.arrayportfoliosRPORFR = []
        this.arrayLeveredPortfolios = []
        this.arrayUnleveredPortfolios = []
        this.arrayRiskStudyPortfollios = []
        this.arraySuppRiskStudyPortfollios = []
        this.dataStudyoptions = [];
        this.companyRankedByoptions = [];
        this.riskPremiumDataStudy.value = "N/A"
        this.riskFreeRateCompanyRankedBy.value = "N/A";
        this.tblHeadrers = this.riskFreeRateHeadresData[RiskPremiumDataStudy.RiskPremiumReportSizeStudy];
    }

    public getHighFinancialRiskStudyZScoreRPORFR() {
        this.arrayHFRSZscoreRPORFR = zscoredataRPORFR;

        this.spinner.begin();
        this.riskClient.getZscore(this.cocInputValues.valuationDate).onceDefined(result => {
            this.arrayHFRSZscoreRPORFR.forEach(obj => {
                if (obj.industry === 'Manufacturing') {
                    obj.sizePremium = obj.zone === 'Gray Zone' ? result.Manufacturing.Buildup.Gray * 100 : result.Manufacturing.Buildup.Distress * 100;
                    obj.arithmeticMeanReturn = obj.zone === 'Gray Zone' ? result.Manufacturing.ArithmeticMeanReturn.Gray * 100 : result.Manufacturing.ArithmeticMeanReturn.Distress * 100;
                    obj.geometricMeanReturn = obj.zone === 'Gray Zone' ? result.Manufacturing.GeometicMeanReturn.Gray * 100 : result.Manufacturing.GeometicMeanReturn.Distress * 100;
                    obj.standardDeviationOfReturns = obj.zone === 'Gray Zone' ? result.Manufacturing.StandardDeviation.Gray * 100 : result.Manufacturing.StandardDeviation.Distress * 100;
                    obj.sumBeta = obj.zone === 'Gray Zone' ? result.Manufacturing.Sumbeta.Gray : result.Manufacturing.Sumbeta.Distress;
                    obj.avgDebtToMVIC = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgDebtToMVIC.Gray * 100 : result.Manufacturing.AvgDebtToMVIC.Distress * 100;
                    obj.avgDebtToEquity = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgDebtToMVoE.Gray * 100 : result.Manufacturing.AvgDebtToMVoE.Distress * 100;
                    obj.avgOperatingMargin = obj.zone === 'Gray Zone' ? result.Manufacturing.AvgOperatingMargin.Gray * 100 : result.Manufacturing.AvgOperatingMargin.Distress * 100;
                } else {
                    obj.sizePremium = obj.zone === 'Gray Zone' ? result.Service.Buildup.Gray * 100 : result.Service.Buildup.Distress * 100;
                    obj.arithmeticMeanReturn = obj.zone === 'Gray Zone' ? result.Service.ArithmeticMeanReturn.Gray * 100 : result.Service.ArithmeticMeanReturn.Distress * 100;
                    obj.geometricMeanReturn = obj.zone === 'Gray Zone' ? result.Service.GeometicMeanReturn.Gray * 100 : result.Service.GeometicMeanReturn.Distress * 100;
                    obj.standardDeviationOfReturns = obj.zone === 'Gray Zone' ? result.Service.StandardDeviation.Gray * 100 : result.Service.StandardDeviation.Distress * 100;
                    obj.sumBeta = obj.zone === 'Gray Zone' ? result.Service.Sumbeta.Gray : result.Service.Sumbeta.Distress;
                    obj.avgDebtToMVIC = obj.zone === 'Gray Zone' ? result.Service.AvgDebtToMVIC.Gray * 100 : result.Service.AvgDebtToMVIC.Distress * 100;
                    obj.avgDebtToEquity = obj.zone === 'Gray Zone' ? result.Service.AvgDebtToMVoE.Gray * 100 : result.Service.AvgDebtToMVoE.Distress * 100;
                    obj.avgOperatingMargin = obj.zone === 'Gray Zone' ? result.Service.AvgOperatingMargin.Gray * 100 : result.Service.AvgOperatingMargin.Distress * 100;
                }
            })
            this.spinner.end();
        })

    }

    /*
        * Appendix B will be used for "Size Premium > Risk Premium Report Risk Study" smoothed premium
        * Appendix A will be used for "Risk premium over risk-free-rate > risk premium report size study" Levered smoothed premium
        * Appendix C will be used for "Risk premium over risk-free-rate > risk premium report size study" Unlevered smoothed premium
        * Appendix D will be used for "Risk premium over risk-free-rate > risk premium report risk study" smoothed premium
    */
    public getRprRegressionValues() {
        this.spinner.begin();

        this.riskClient.GetRprRegressionData(this.cocInputValues.valuationDate).onceDefined(result => {
            this.rprRegressionAppendixA = result.AppexdixA;
            this.rprRegressionAppendixB = result.AppexdixB;
            this.rprRegressionAppendixC = result.AppexdixC;
            this.rprRegressionAppendixD = result.AppexdixD;
            this.spinner.end();
            this.getAllTabsData()

        });
    }

    //#endregion

    public getStorageFile(fileName: string): void {
        this.filesClient.getStorageFile(fileName, ContainerTypeEnum.resources).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }


    public getErpSupplyData(dataAsOf: string): void {
        this.spinner.begin();
        this.erpintlClient.GetERPSupplymentartData(dataAsOf).subscribe((data: ErpSupplymetryDetailData) => {
            this.spinner.end();
            this.erpSupplymentryData = data;
            this.erpSupplymentryData.lstErpSupplymentry = this.erpSupplymentryData.lstErpSupplymentry.map(({ BillBondMaturity, ...rest }) => rest)
        })
    }


    public loadLeveredBetas(region: string) {

        this.industryBetasResponse = this.intlIndustryBetasResponse.find(s => s.RegionName === region)?.ViewInputBetas as IndustryBetas[];

        if (this.industryBetasResponse && this.industryBetasResponse.length > 0) {
            this.industryBetasValuationDate = this.industryBetasResponse[0]?.BetaDataAsOf ?
                this.industryBetasResponse[0].BetaDataAsOf.toString() : new Date().toString();
            this.leveredBetas = this.industryBetasResponse.map(beta => {
                return {
                    industry: this.GetIndustryLabel(beta.IndustryCode, beta.IndustryDescription),
                    fullInformation: beta.FullInformationBeta,
                    OLS: beta.LeveredOLSBeta,
                    Sum: beta.LeveredSumBeta,
                    Vasicek: beta.LeveredVasicekAdjusted,
                    Blume: beta.LeveredBlumeAdjusted,
                    Peer: beta.LeveredPeerGroup
                } as BetaIndustryData
            });
        } else {
            this.industryBetasValuationDate = new Date().toString();
            this.leveredBetas = [];
        }

        this.getIndustryListByRegion(this.industryBetasResponse);
        // if (this.irpBetasLoaded === false) {
        this.setIRPDataOptions(this.leveredBetas);
        if (this.leveredBetas.length == 0) {
            this.makeLeverageAndCoverageOptionNA();
        }

        // }
        //this.irpBetasLoaded = true;
    }
    /**
     * if Geographic Coverage selected is United States and valuation date selected is older than Sep 30, 2020; 
     * only then return SIC label, else retun GICS label
     */
    public GetIndustryLabel(industryCode: string, industryDescription: string): string {
        if (!this.gicsData && this.betaIndustryGeoCoverage.value === 'United States') {
            return `SIC ${industryCode} - ${industryDescription}`;
        } else {
            return `GICS ${industryCode} - ${industryDescription}`;
        }
    }

    public setIRPDataOptions(betaValues: BetaIndustryData[]) {

        if (this.erp.length > 0 && betaValues.length > 0) {
            this.irpDataOptions = [];
            const erpDataOptions = this.erp;
            erpDataOptions.forEach((item) => {
                this.irpDataOptions.push({
                    name: `${item.datapoint}`,
                    value: `${item.datapoint}`,
                    type: ComboboxDataType.String,
                    disabled: false
                })
            });
            this.irptabHeaders = "Industry Risk Premium (RPi)";
            this.irpTableHeaders = this.betaHeadresData[BetaLeverage.IRP];
            this.industryRiskPremia.value = `${erpDataOptions[0]?.datapoint}`
            this.setERPinIRP(this.industryRiskPremia.value);
        }
        else {
            this.makeIrpDataOptionsNA();
        }
    }

    public makeLeverageAndCoverageOptionNA() {
        this.industryDataOptions = [];
        this.betaIndustry.value = 'N/A';

        this.betaIndustryGeoCoverageOptions = [];
        this.betaIndustryGeoCoverage.value = 'N/A';
    }

    public makeIrpDataOptionsNA() {
        this.irpDataOptions = [];
        this.industryRiskPremia.value = 'N/A';
    }

    public setERPinIRP(erp: string) {
        this.loadERPIRPValues(erp);
    }

    public loadERPIRPValues(erpOptionValue: string) {
        this.industryRiskPremiaBetas = [];
        if (this.erp.length > 0) {
            const erpValue = this.erp.find(value => value.datapoint == erpOptionValue)?.value;
            if (erpValue) {
                this.erpValueDisplayed = erpOptionValue;
                if (this.isBetaDisplayed === BetaLeverage.LeveredBetas) {
                    this.industryRiskPremiaBetas = this.leveredBetas.map(beta => {
                        return {
                            industry: beta.industry,
                            fullInformation: beta.fullInformation !== 0 ? this.roundOff(beta.fullInformation) * erpValue - erpValue : 0,
                            OLS: beta.OLS !== 0 ? this.roundOff(beta.OLS) * erpValue - erpValue : 0,
                            Sum: beta.Sum !== 0 ? this.roundOff(beta.Sum) * erpValue - erpValue : 0,
                            Vasicek: beta.Vasicek !== 0 ? this.roundOff(beta.Vasicek) * erpValue - erpValue : 0,
                            Blume: beta.Blume !== 0 ? this.roundOff(beta.Blume) * erpValue - erpValue : 0,
                            Peer: beta.Peer !== 0 ? this.roundOff(beta.Peer) * erpValue - erpValue : 0
                        } as BetaIndustryData
                    });
                } else {
                    this.industryRiskPremiaBetas = this.unleveredBetas.map(beta => {
                        return {
                            industry: beta.industry,
                            fullInformation: beta.fullInformation !== 0 ? this.roundOff(beta.fullInformation) * erpValue - erpValue : 0,
                            OLS: beta.OLS !== 0 ? this.roundOff(beta.OLS) * erpValue - erpValue : 0,
                            Sum: beta.Sum !== 0 ? this.roundOff(beta.Sum) * erpValue - erpValue : 0,
                            Vasicek: beta.Vasicek !== 0 ? this.roundOff(beta.Vasicek) * erpValue - erpValue : 0,
                            Blume: beta.Blume !== 0 ? this.roundOff(beta.Blume) * erpValue - erpValue : 0,
                            Peer: beta.Peer !== 0 ? this.roundOff(beta.Peer) * erpValue - erpValue : 0
                        } as BetaIndustryData
                    });
                }
            }
        }
    }

    public roundOff(value: number): number {
        return (+NumberFormatUtil.numberWithCommas(value));
    }

    public loadUnleveredBetas(region: string) {

        this.industryBetasResponse = this.intlIndustryBetasResponse.find(s => s.RegionName === region)?.ViewInputBetas as IndustryBetas[];

        if (this.industryBetasResponse && this.industryBetasResponse.length > 0) {
            this.industryBetasValuationDate = this.industryBetasResponse[0]?.BetaDataAsOf ?
                this.industryBetasResponse[0].BetaDataAsOf.toString() : new Date().toString();
            this.unleveredBetas = this.industryBetasResponse.map(beta => {
                return {
                    industry: this.GetIndustryLabel(beta.IndustryCode, beta.IndustryDescription),
                    fullInformation: 0,
                    OLS: beta.UnleveredOLSBeta,
                    Sum: beta.UnleveredSumBeta,
                    Vasicek: beta.UnleveredVasicekAdjusted,
                    Blume: beta.UnleveredBlumeAdjusted,
                    Peer: beta.UnleveredPeerGroup
                } as BetaIndustryData
            });
        } else {
            this.industryBetasValuationDate = new Date().toString();
            this.unleveredBetas = [];
        }

        this.setIRPDataOptions(this.unleveredBetas);

        if (this.unleveredBetas.length == 0) {
            this.makeLeverageAndCoverageOptionNA();
        }

        this.getIndustryListByRegion(this.industryBetasResponse);

        //this.irpBetasLoaded = true;
    }

    public setDebtBetaOptions() {
        this.DebtBetasTabHeading = "Debt Betas by Credit Rating";
        this.DebtBetasTableHeaders = this.betaHeadresData[BetaLeverage.DebtBetas];
        const credit = this.usDebtsBetasResponse.map((beta) => beta.creditRating);
        this.creditSortData();

    }
    public creditSortData() {
        const creditOrder: { [key: string]: number } = {
            "Aaa": 1,
            "Aa": 2,
            "A": 3,
            "Baa": 4,
            "Ba": 5,
            "B": 6,
            "Caa": 7,
            "Ca-D": 8

        };
        this.usDebtBetaValues = this.usDebtsBetasResponse.sort((a, b) => creditOrder[a.creditRating] - creditOrder[b.creditRating]).map((beta) => {
            return {
                moodysRatings: beta.creditRating,
                value: beta.debtBeta
            }
        })
    }
    public setBetaDataOptions() {
        // this.industryDataOptions = [];

        // const dataOptions = [BetaLeverage.LeveredBetas, BetaLeverage.UnleveredBetas];
        // dataOptions.forEach((item) => {
        //     this.industryDataOptions.push({
        //         name: item,
        //         value: item,
        //         type: ComboboxDataType.String,
        //         disabled: false
        //     });
        // });

        // this.tabHeaders = "Industry-level Betas";
        // this.tableHeaders = this.betaHeadresData[BetaLeverage.LeveredBetas];
        // this.leverageBeta.value = BetaLeverage.LeveredBetas;
        this.betaIndustry.value = BetaLeverage.LeveredBetas;
        this.setBetaLeverageData(this.betaIndustry.value);
        //this.updateGeoCoverage();


    }

    public updateGeoCoverage() {
        this.betaIndustryGeoCoverageOptions = [];
        if (this.usrPerms.SubsType == EnumSubsType.IneAndCoc) {
            this.betaIndustryGeoCoverageOptions = [];
            if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'EUR') {
                this.betaIndustryGeoCoverageOptions.push({
                    name: 'Eurozone',
                    value: 'Eurozone',
                    type: ComboboxDataType.String,
                    disabled: false
                },
                    {
                        name: 'European Union',
                        value: 'European Union',
                        type: ComboboxDataType.String,
                        disabled: false
                    },
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                this.betaIndustryGeoCoverage.value = this.betaIndustryGeoCoverageOptions[0].value;
                this.betaIndustryGeoCoverageCurrency.value = 'EUR';
                this.betaIndustryGeoCoverageConfig.disabled = false;
            } else if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'GBP') {
                this.betaIndustryGeoCoverageOptions.push(
                    {
                        name: 'United Kingdom',
                        value: 'United Kingdom',
                        type: ComboboxDataType.String,
                        disabled: false
                    },
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                this.betaIndustryGeoCoverage.value = this.betaIndustryGeoCoverageOptions[0].value;
                this.betaIndustryGeoCoverageCurrency.value = 'GBP';
                this.betaIndustryGeoCoverageConfig.disabled = false;

            } else if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'USD') {
                this.betaIndustryGeoCoverageOptions.push({
                    name: 'United States',
                    value: 'United States',
                    type: ComboboxDataType.String,
                    disabled: false
                },
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                this.betaIndustryGeoCoverage.value = this.betaIndustryGeoCoverageOptions[0].value;
                this.betaIndustryGeoCoverageConfig.disabled = false;
                this.betaIndustryGeoCoverageCurrency.value = 'USD';
            } else {
                this.betaIndustryGeoCoverage.value = this.cocInputValues.investorPerspectiveregion;
                this.betaIndustryGeoCoverageCurrency.value = this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC);
            }
        } else if (this.usrPerms.SubsType == EnumSubsType.Coc) {
            this.betaIndustryGeoCoverageOptions.push({
                name: 'United States',
                value: 'United States',
                type: ComboboxDataType.String,
                disabled: false
            },
                {
                    name: 'World',
                    value: 'World',
                    type: ComboboxDataType.String,
                    disabled: true,
                    enableLockIcon: true,
                });
            this.betaIndustryGeoCoverage.value = 'United States';
            this.betaIndustryGeoCoverageCurrency.value = 'USD';
        } else if (this.usrPerms.SubsType == EnumSubsType.Ine) {
            this.betaIndustryGeoCoverageOptions = [];
            if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'EUR') {
                this.betaIndustryGeoCoverageOptions.push({
                    name: 'Eurozone',
                    value: 'Eurozone',
                    type: ComboboxDataType.String,
                    disabled: false
                },
                    {
                        name: 'European Union',
                        value: 'European Union',
                        type: ComboboxDataType.String,
                        disabled: false
                    },
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                this.betaIndustryGeoCoverage.value = this.betaIndustryGeoCoverageOptions[0].value;
                this.betaIndustryGeoCoverageCurrency.value = 'EUR';
                this.betaIndustryGeoCoverageConfig.disabled = false;
            } else if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'GBP') {
                this.betaIndustryGeoCoverageOptions.push(
                    {
                        name: 'United Kingdom',
                        value: 'United Kingdom',
                        type: ComboboxDataType.String,
                        disabled: false
                    },
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    });
                this.betaIndustryGeoCoverage.value = this.betaIndustryGeoCoverageOptions[0].value;
                this.betaIndustryGeoCoverageCurrency.value = 'GBP';
                this.betaIndustryGeoCoverageConfig.disabled = false;

            } else if (this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC) == 'USD') {
                this.betaIndustryGeoCoverageOptions.push(
                    {
                        name: 'World',
                        value: 'World',
                        type: ComboboxDataType.String,
                        disabled: false
                    },
                    {
                        name: 'United States',
                        value: 'United States',
                        type: ComboboxDataType.String,
                        disabled: true,
                        enableLockIcon: true
                    });
                this.betaIndustryGeoCoverage.value = 'World';
                this.betaIndustryGeoCoverageCurrency.value = 'USD';
            } else {
                this.betaIndustryGeoCoverage.value = this.cocInputValues.investorPerspectiveregion;
                this.betaIndustryGeoCoverageCurrency.value = this.findCurrencyAcronym(this.cocInputValues.currencyOfWACC);
            }
        }
    }

    public findCurrencyAcronym(currency: string | null | undefined): string {
        if (currency !== null && currency !== undefined) {
            var index = currency.indexOf('-') ?? -1;
            return currency.substring(index + 2, index + 5)
        }
        return "";
    }

    public setBetaLeverageData(beta: string) {
        this.isBetaDisplayed = beta;
        if (this.betaIndustryGeoCoverage.value) {
            this.onBetaIndustryGeoCoverageChange(this.betaIndustryGeoCoverage.value);
        }
    }

    public onBetaIndustryGeoCoverageChange(region: string) {

        this.isBetaDisplayed === BetaLeverage.LeveredBetas ? this.loadLeveredBetas(region) : this.loadUnleveredBetas(region);
        this.loadERPIRPValues(this.erpValueDisplayed);
        this.IndustryBetasDataAvailable = this.leveredBetas?.length > 0 || this.unleveredBetas?.length > 0 ? true : false;
        this.ShowUSDebtBetas = this.cocInputValues.investorPerspectiveregion === "United States" && this.betaIndustryGeoCoverage.value == "United States" && (this.usrPerms.SubsType == EnumSubsType.Coc || this.usrPerms.SubsType == EnumSubsType.IneAndCoc) ? true : false;

        //Added condition to avoid re-execution of the fuction
        if (!this.DebtBetasSelectedRegion || this.DebtBetasSelectedRegion !== region) {
            this.loadDebtBetasDataByRegionSelected(region);
        }

    }

    private loadDebtBetasDataByRegionSelected(region: string) {
        this.DebtBetasSelectedRegion = region;
        this.DebtBetasTabHeading = "Debt Betas by Credit Rating";
        this.DebtBetasTableHeaders = this.betaHeadresData[BetaLeverage.DebtBetas];
        this.debtBetasReponse = this.debtBetasByRegionCurrencyResponse.find(r => r.Region === region)?.IntlDebtBetas as IntlDebtBetasData[];

        if (this.debtBetasReponse && this.debtBetasReponse.length > 0) {
            this.debtBetasValuationDate = this.debtBetasReponse[0]?.DataAsOf ? this.debtBetasReponse[0].DataAsOf.toString() : new Date().toString();

            let creditOrder: { [key: string]: number };
            if (region === "United States") {
                creditOrder = { "Aaa": 1, "Aa": 2, "A": 3, "Baa": 4, "Ba": 5, "B": 6, "Caa": 7, "Ca-D": 8 }
            } else {
                creditOrder = { "AAA": 1, "AA": 2, "A": 3, "Baa": 4, "High-yield": 5 }
            }

            this.CommonDebtBetaArrayForUI = this.debtBetasReponse.sort((a, b) => creditOrder[a.creditRating] - creditOrder[b.creditRating]).map((beta) => {
                return {
                    moodysRatings: beta.creditRating,
                    value: beta.debtBeta
                }
            });

        } else {
            this.debtBetasValuationDate = this.cocInputValues.valuationDate;
            this.CommonDebtBetaArrayForUI = [];
        }
    }

    public getIndustryListByRegion(result: IndustryBetas[]) {

        if (result && result.length > 0) {
            this.betaIndustryIdList = result.map(ids => {
                return {
                    industryId: ids.IndustryId,
                    industryCode: this.GetIndustryLabel(ids.IndustryCode, ids.IndustryDescription),
                    industryDescription: ids.IndustryDescription
                } as BetaIndustryInfo
            });
        } else {
            this.betaIndustryIdList = [];
        }

    }

    public onCompanyRankedByChnage(companyRankedBy: any) {
        let type = RiskPremiumReportSizeStudy[companyRankedBy.value] as InputType;

        this.arrayRPRSSLeveredTypePortfoio = this.arrayLeveredPortfolios.filter(item => item.InputType === InputType[type]).map(port => {
            return {
                id: port.Id,
                name: port.Name,
                dataAsOf: port.DataAsOf,
                lowBreakPoint: port.Lower,
                highBreakPoint: port.Upper,
                leveredRPORFR: port.Result * 100,
            } as LeveredPortfolio
        });

        this.arrayRPRSSUnleveredTypePortfolio = this.arrayUnleveredPortfolios.filter(item => item.InputType === InputType[type]).map(port => {
            return {
                id: "" + port.Id,
                name: port.Name,
                lowBreakPoint: port.Lower,
                highBreakPoint: port.Upper,
                unleveredRPORFR: port.Result * 100,
            } as UnleveredPortfolio
        });

        const leveredPortfolioArray: LeveredPortfolio[] = this.arrayRPRSSLeveredTypePortfoio;
        const unleveredPortfolioArray: UnleveredPortfolio[] = this.arrayRPRSSUnleveredTypePortfolio;
        this.arrayRPSTypePortfolio = leveredPortfolioArray.map(leveredItem => {
            const unleveredItem = unleveredPortfolioArray.find(unleveredItem => +unleveredItem.id === leveredItem.id);
            return {
                id: "" + leveredItem.id,
                name: leveredItem.name,
                dataAsOf: leveredItem.dataAsOf,
                lowBreakPoint: leveredItem.lowBreakPoint,
                highBreakPoint: leveredItem.highBreakPoint,
                leveredRPORFR: leveredItem.leveredRPORFR,
                unleveredRPORFR: unleveredItem?.unleveredRPORFR
            } as RiskPremiumPortfolio

        });

        this.riskPremiumOverRFRValuationDate = this.arrayRPSTypePortfolio[0].dataAsOf ? this.arrayRPSTypePortfolio[0].dataAsOf : new Date().toString();
        // this.exportService.rpOverRfRateDataAsOf = this.riskPremiumOverRFRValuationDate;

        this.sizeStudyLeveredRegressionValues = this.rprRegressionAppendixA[type];
        this.sizeStudyUnleveredRegressionValues = this.rprRegressionAppendixC[type];
    }

    public onCompanyRankedbyRiskChange(companyRankedBy: any) {
        let type = RiskPremiumReportRiskStudy[companyRankedBy.value] as InputType;
        if (type) {
            this.arrayRPRRSLeveredTypePortfolio = this.arrayRiskStudyPortfollios.filter(item => item.InputType === InputType[type]).map(port => {
                return {
                    id: + port.Id,
                    name: port.Name,
                    lowBreakPoint: port.Lower,
                    highBreakPoint: port.Upper,
                    leveredRPORFR: port.Result * 100,
                } as LeveredPortfolio
            });
        }
        this.riskStudyRegressionValues = this.rprRegressionAppendixD[type];

    }

    public setDataOptions() {
        if (this.riskPremiumDataStudy.value != 'N/A') {
            this.dataStudyoptions = [];
            const dataOptions = [RiskPremiumDataStudy.RiskPremiumReportSizeStudy, RiskPremiumDataStudy.RiskPremiumReportRiskStudy, RiskPremiumDataStudy.HighFinancialRiskStudy];
            dataOptions.forEach((item) => {
                this.dataStudyoptions.push({
                    name: item,
                    value: item,
                    type: ComboboxDataType.String,
                    disabled: false
                });
            });
            this.tblHeadrers = this.riskFreeRateHeadresData[RiskPremiumDataStudy.RiskPremiumReportSizeStudy];
            this.riskPremiumDataStudy.value = RiskPremiumDataStudy.RiskPremiumReportSizeStudy;
            this.setCompanyData(RiskPremiumDataStudy.RiskPremiumReportSizeStudy);
        }
    }

    public setCompanyData(dataStudy: string) {
        if (this.riskFreeRateCompanyRankedBy.value != 'N/A') {
            this.tblHeadrers = this.riskFreeRateHeadresData[dataStudy];
            this.companyRankedByoptions = [];
            const companyOptions = this.riskFreeRateCompanyRankedData[dataStudy];
            if (dataStudy !== RiskPremiumDataStudy.HighFinancialRiskStudy) {
                Object.keys(companyOptions).forEach((key => {
                    this.companyRankedByoptions.push({
                        name: key,
                        value: key,
                        type: ComboboxDataType.String,
                    });
                }))
                this.riskFreeRateCompanyRankedByConfig.disabled = false;
                if (dataStudy === RiskPremiumDataStudy.RiskPremiumReportSizeStudy) {
                    this.riskFreeRateCompanyRankedBy.value = 'Market Value of Common Equity';
                    this.onCompanyRankedByChnage(this.riskFreeRateCompanyRankedBy);
                } else {
                    this.riskFreeRateCompanyRankedBy.value = 'Operating Margin';
                    this.onCompanyRankedbyRiskChange(this.riskFreeRateCompanyRankedBy)
                }
                // this.companyRankedBy.value = dataStudy === RiskPremiumDataStudy.RiskPremiumReportSizeStudy ? 'Market Value of Common Equity' : 'Operating Margin';
                // this.onCompanyRankedByChnage(this.companyRankedBy)
            } else {
                this.riskFreeRateCompanyRankedByConfig.disabled = true;
                this.riskFreeRateCompanyRankedBy.value = companyOptions;
            }
        }
    }

    public getERPIRPValuesforExport(erpDatapoint: string, type: string): BetaIndustryData[] {
        const erpValue = this.erp.find(value => value.datapoint == erpDatapoint)?.value;
        let industryRiskPremiaBetas: BetaIndustryData[] = [];
        if (erpValue) {
            if (type === BetaLeverage.LeveredBetas) {
                industryRiskPremiaBetas = this.leveredBetas.map(beta => {
                    return {
                        industry: beta.industry,
                        fullInformation: beta.fullInformation !== 0 ? this.roundOff(beta.fullInformation) * erpValue - erpValue : 0,
                        OLS: beta.OLS !== 0 ? this.roundOff(beta.OLS) * erpValue - erpValue : 0,
                        Sum: beta.Sum !== 0 ? this.roundOff(beta.Sum) * erpValue - erpValue : 0,
                        Vasicek: beta.Vasicek !== 0 ? this.roundOff(beta.Vasicek) * erpValue - erpValue : 0,
                        Blume: beta.Blume !== 0 ? this.roundOff(beta.Blume) * erpValue - erpValue : 0,
                        Peer: beta.Peer !== 0 ? this.roundOff(beta.Peer) * erpValue - erpValue : 0
                    } as BetaIndustryData
                });
            } else {
                industryRiskPremiaBetas = this.industryBetasResponse.map(beta => {
                    return {
                        industry: this.gicsData ? `GICS  ${beta.IndustryCode} - ${beta.IndustryDescription}` :
                            `SIC  ${beta.IndustryCode} - ${beta.IndustryDescription}`,
                        fullInformation: 0,
                        OLS: beta.UnleveredOLSBeta !== 0 ? this.roundOff(beta.UnleveredOLSBeta) * erpValue - erpValue : 0,
                        Sum: beta.UnleveredSumBeta !== 0 ? this.roundOff(beta.UnleveredSumBeta) * erpValue - erpValue : 0,
                        Vasicek: beta.UnleveredVasicekAdjusted !== 0 ? this.roundOff(beta.UnleveredVasicekAdjusted) * erpValue - erpValue : 0,
                        Blume: beta.UnleveredBlumeAdjusted !== 0 ? this.roundOff(beta.UnleveredBlumeAdjusted) * erpValue - erpValue : 0,
                        Peer: beta.UnleveredPeerGroup !== 0 ? this.roundOff(beta.UnleveredPeerGroup) * erpValue - erpValue : 0
                    } as BetaIndustryData
                });
            }
        }
        return industryRiskPremiaBetas;
    }

    public getUnleverdBetaforExport(): BetaIndustryData[] {
        let unleverdBetas = this.industryBetasResponse.map(beta => {
            return {
                industry: this.gicsData ? `GICS  ${beta.IndustryCode} - ${beta.IndustryDescription}` :
                    `SIC  ${beta.IndustryCode} - ${beta.IndustryDescription}`,
                fullInformation: 0,
                OLS: beta.UnleveredOLSBeta,
                Sum: beta.UnleveredSumBeta,
                Vasicek: beta.UnleveredVasicekAdjusted,
                Blume: beta.UnleveredBlumeAdjusted,
                Peer: beta.UnleveredPeerGroup
            } as BetaIndustryData
        });

        return unleverdBetas;
    }

    public mapRPSSsupplymentryData() {
        this.arraysupplyDecilPortfolio = this.arrayportfolios.map((port: any) => {
            return {
                id: port.Id,
                name: port.Name,
                dataAsOf: port.DataAsOf,
                lowBreakPoint: port.Lower,
                highBreakPoint: port.Upper,
                leveredRPORFR: port.Result * 100,
                numberOfCompanies: port.NumberOfCompanies,
                arithameticMeanReturn: port.ArithmeticMeanReturn,
                geometricMeanReturn: port.GeometricMeanReturn,
                sumBetaSince1963: port.SumBetaSince1963,
                standardDeviation: port.StandardDeviationOfReturns,
                avgUnleverdBeta: port.UnleveredBeta,
                avgOperatingMarigin: port.AverageOperatingMargin,
                avgDebtEquity: port.AverageDebtToMvoe,
                avgDebtMvc: port.AverageDebtMvic,
                avgCvOfOperatingMarigin: port.AverageCovom,
                avgCeOfRoe: port.AverageCovroe,
                InputType: port.InputType,
                PortofolioSize: port.PortofolioSize

            } as RiskPremiumSizeStudyPortfolio
        });
    }

    public getCompanyNames(type: string, selectedOptionLeveredUnlevered: string, industryId: number) {

        var BetaTypeSelection = '';
        var industryBetaInfo;

        //Re-using dataAsOf returned by API call, because for this date we will have data available in DB
        var dataAsOf = this.industryBetasValuationDate;

        var industryCodeDesc = this.betaIndustryIdList.find(id => id.industryId === industryId)?.industryCode;

        //depending on whether user has selected Levered or Unlevered betas from the dropdown,
        //we check the FIBs or Other beta values, and make getComapnyNames call
        if (selectedOptionLeveredUnlevered === BetaLeverage.LeveredBetas) {
            industryBetaInfo = this.leveredBetas.find(id => id.industry == industryCodeDesc) as BetaIndustryData;
        } else {
            industryBetaInfo = this.unleveredBetas.find(id => id.industry == industryCodeDesc) as BetaIndustryData;
        }

        const FibBetaValue = industryBetaInfo?.fullInformation;
        var FibsDataAvailable = FibBetaValue && FibBetaValue > 0 ? true : false;

        var OtherBetasAvailable = this.IsNumberValid(industryBetaInfo?.Blume) || this.IsNumberValid(industryBetaInfo?.OLS) ||
            this.IsNumberValid(industryBetaInfo?.Peer) || this.IsNumberValid(industryBetaInfo?.Sum) ||
            this.IsNumberValid(industryBetaInfo?.Vasicek)

        if (Util.notNull(this.betaIndustryGeoCoverage.value) && Util.notNull(this.betaIndustryGeoCoverageCurrency.value)) {
            if (type === this.BetaTypeOptions.Fibs && FibsDataAvailable) {
                BetaTypeSelection = "FIB";
            } else if (type === this.BetaTypeOptions.Others && OtherBetasAvailable) {
                BetaTypeSelection = "OTHERS";
            }

            if (!Util.isNullOrEmpty(BetaTypeSelection)) {
                this.spinner.begin();
                this.companyClient.getComapnyNames(industryId, dataAsOf, BetaTypeSelection, this.betaIndustryGeoCoverage.value, this.betaIndustryGeoCoverageCurrency.value)
                    .subscribe(comapnyNames => {
                        this.BetaComapniesInfo = comapnyNames;
                        this.spinner.end();
                    });
            } else {
                this.BetaComapniesInfo = [];
            }

        } else {
            this.BetaComapniesInfo = [];
        }

    }

    private IsNumberValid(value: number | null) {
        if (Util.notNull(value) && Util.isNumber(value)) {
            return value > 0
        }
        return false;
    }

    public defineUserPermissions(user: SubscriptionData[]) {
        const subscriptionFeatures = user.filter(item => (item.productType === ProductType.Coc && item.isExpired === false) || (item.productType === ProductType.Ine && item.isExpired === false));
        const isCocProExists = subscriptionFeatures.find(s => s.productType === ProductType.Coc);
        const ineProExist = subscriptionFeatures.find(d => d.productType === ProductType.Ine);

        try {
            if (isCocProExists && ineProExist) {
                this.usrPerms.SubsType = EnumSubsType.IneAndCoc;
            } else if (isCocProExists) {
                this.usrPerms.SubsType = EnumSubsType.Coc;
            } else {
                this.usrPerms.SubsType = EnumSubsType.Ine;
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    public getDebtBetaTrendsOverTime(dataAsOf: string): Observable<DebtBetaTrendsOverTime[]> {
        return this.usIndustryClient.getDebtBetaTrendOverTime(dataAsOf);
    }

}