import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { Category } from '../enums/category';
import { MetricGroup } from '../enums/metric-group';
import { FlatenMtericCategory, FlattenMteric, MetricCategory, MetricDetails } from '../models/metric-category.model';
import { MetricRow } from '../models/metric-row.model';
import { MetricTable } from '../models/metric-table.model';
import { MetricValues } from '../models/metric-values.model';
import { TearSheet } from '../tear-sheet.metrics';
import { CapitalStructureChartUtil } from './capital-structure-chart.util';
import { CostOfEquityCapitalChartUtil } from './cost-of-equity-capital-chart.util';
import { EnterpriseValuationChartUtil } from './enterprise-valuation-chart.util';
import { TearSheetUtil } from './tear-sheet.util';
import { WaccChartUtil } from './wacc-chart.util';
import { Categories } from '../const/categories.const';


export class MetricsUtil {

    public static processMetricMapping(
        data: IndustryMetric[],
        codeId: string,
        dataAsOf: Date,
        subscriptionData?: SubscriptionData,
        region?: string,

    ): MetricRow[] {
        const metricRows: MetricRow[] = [];
        this.changeLayout(codeId, dataAsOf, region);

        TearSheet(subscriptionData).forEach((row) => {
            const metricCategories: MetricCategory[] = [];

            row.categories.forEach((cat) => {
                if (cat.category === Category.CapitalStructure) {
                    const capitalStructureChart = CapitalStructureChartUtil.getCapitalStructureChartMetric(data);
                    if (capitalStructureChart) {
                        metricCategories.push(capitalStructureChart);
                    }
                } else {
                    const result: MetricCategory = {
                        metricCategoryId: cat.category,
                        metricCategoryName: cat.categoryName,
                        showCompositeLabels: cat.showCompositeLabels,
                        // showCompositeLabels: this.showCompositeLabels(cat.category, changeLayout, cat.showCompositeLabels),
                        metrics: [],
                        chart: null,
                        position: cat.position
                        // position: this.getPosition(cat.category, changeLayout, cat.position)
                    };
                    cat.metricGroups.forEach((y) => {
                        const filteredData = data.filter((z) => y.metrics.includes(z.MetricId));

                        if (filteredData.length > 0) {
                            const metricValue: MetricValues = {
                                name: y.metricGroupName,
                                tables: this.convertMetricValues(filteredData),
                                trendsOverTimeColumn: y.trendsOverTimeColumn ? y.trendsOverTimeColumn : false,
                                trendsOverTimeMetricTitle: y.trendsOverTimeMetricTitle ? y.trendsOverTimeMetricTitle : false
                            };
                            result.metrics.push(metricValue);
                        } else {
                            if (y.metricGroup === MetricGroup.EnterpriseValuation) {
                                const enterpriseValuationChart = EnterpriseValuationChartUtil.getEnterpriseValuationChartMetric(data);
                                if (enterpriseValuationChart) {
                                    result.chart = enterpriseValuationChart;
                                }
                            }
                            if (y.metricGroup === MetricGroup.CostOfEquityCapital) {
                                const costOfEquityCapitalChart = CostOfEquityCapitalChartUtil.getCostOfEquityCapitalChartMetric(data);
                                if (costOfEquityCapitalChart) {
                                    result.chart = costOfEquityCapitalChart;
                                }
                            }
                            if (y.metricGroup === MetricGroup.Wacc) {
                                const waccChartMetric = WaccChartUtil.getWaccChartMetric(data);
                                if (waccChartMetric) {
                                    result.chart = waccChartMetric;
                                }
                            }
                        }
                    });
                    metricCategories.push(result);
                }
            });

            const metricRow: MetricRow = {
                categories: this.sortCategories(metricCategories),
            };
            if (metricRow.categories.length > 1) {
                if (metricRow.categories[0].metrics.length === 0) {
                    metricRow.categories[1].showCompositeLabels = true;
                }
            }
            metricRows.push(metricRow);
        });

        return metricRows;
    }

    private static convertMetricValues(industryData: IndustryMetric[]): MetricTable[] {
        const metricTables: MetricTable[] = [];

        industryData.forEach((x) => {
            const isBeta = TearSheetUtil.isBeta(x);

            const value: MetricTable = {
                metricId: x.MetricId,
                metricName: x.MetricName,
                metricCategory: x.MetricCategory,
                columns: TearSheetUtil.getColumnNames(x),
                medianLatest: TearSheetUtil.roundTearSheetValue(x.MedianLatest, isBeta),
                medianAverage: TearSheetUtil.roundTearSheetValue(x.MedianAverage, isBeta),
                sicCompositeLatest: TearSheetUtil.roundTearSheetValue(x.SICCompositeLatest, isBeta),
                sicCompositeAvergae: TearSheetUtil.roundTearSheetValue(x.SICCompositeAverage, isBeta),
                largeCompositeLatest: TearSheetUtil.roundTearSheetValue(x.LargeCompositeLatest, isBeta),
                largeCompositeAverage: TearSheetUtil.roundTearSheetValue(x.LargeCompositeAverage, isBeta),
                smallCompositeLatest: TearSheetUtil.roundTearSheetValue(x.SmallCompositeLatest, isBeta),
                smallCompositeAverage: TearSheetUtil.roundTearSheetValue(x.SmallCompositeAverage, isBeta),
                highFinancialRiskLatest: TearSheetUtil.roundTearSheetValue(x.HighFinancialRiskLatest, isBeta),
                highFinancialRiskAverage: TearSheetUtil.roundTearSheetValue(x.HighFinancialRiskAverage, isBeta),
                showAverageValues: TearSheetUtil.showAverageValues(x)
            };

            metricTables.push(value);
        });

        return metricTables;
    }

    public static changeLayout(industryCode: string, dataAsOf: Date, region: string | undefined): boolean {
        const usStartDate = new Date(2017, 2, 31);
        const intlStartDate = new Date(2018, 8, 30);
        const targetDate = new Date(dataAsOf);

        if (region && region !== 'United States') {
            return this.isIndustryWithSpecificCodes(industryCode, targetDate, intlStartDate);
        }

        return this.isIndustryWithSpecificCodes(industryCode, targetDate, usStartDate);
    }

    private static isIndustryWithSpecificCodes(industryCode: string, targetDate: Date, startDate: Date): boolean {
        return industryCode[0] === '6' && targetDate >= startDate ||
            industryCode[0] === '4' && targetDate >= startDate;
    }

    private static sortCategories(categories: MetricCategory[]): MetricCategory[] {
        return categories.sort((a, b) => {
            return (a.position > b.position) ? 1 : -1;
        });
    }


    //Restructuring Objects for latest Ui changes.
    public static getFlattenData(
        datas: IndustryMetric[],
        codeId: string,
        dataAsOf: Date,
        subscriptionData?: SubscriptionData,
        region?: string): FlatenMtericCategory[] {

        const flatendata: FlatenMtericCategory[] = []
        const data = this.processMetricMapping(datas, codeId, dataAsOf, subscriptionData, region);
        data.forEach((catogery) => {
            catogery.categories.forEach((metricCat) => {
                flatendata.push(this.mapNewData(metricCat));
            });
        });

        if (flatendata.length > 0) {

            const liquidityIndex = flatendata.findIndex(d => d.metricCategoryId == Category.LiquidityRatio);
            const profitabilityIndex = flatendata.findIndex(d => d.metricCategoryId == Category.ProfitabilityRatio);
            if (liquidityIndex > -1 && profitabilityIndex > -1) {
                const mergedData = this.mergeTwoMetricObjects(flatendata[liquidityIndex], flatendata[profitabilityIndex], Categories.LiquidtyandProfitability);
                flatendata[liquidityIndex] = mergedData;
                flatendata.splice(profitabilityIndex, 1);
            };

            const leverdIndex = flatendata.findIndex(d => d.metricCategoryId == Category.BetasLevered);
            const unleverdIndex = flatendata.findIndex(d => d.metricCategoryId == Category.BetasUnlevered);
            if (leverdIndex > -1 && unleverdIndex > -1) {
                const mergedData = this.mergeTwoMetricObjects(flatendata[leverdIndex], flatendata[unleverdIndex], Categories.Betas);
                flatendata[leverdIndex] = mergedData;
                flatendata.splice(unleverdIndex, 1);
            };
        }

        return flatendata.filter(m => m.metrics?.metricDetails.length > 0);


    }


    private static mapNewData(catogery: MetricCategory): FlatenMtericCategory {
        const flatCatogery = {} as FlatenMtericCategory;
        const metricDetails: MetricDetails[] = []
        const medianLatest: string[] = ['Median'];
        const sicComposite: string[] = ['Industry Composite'];
        const largeComposite: string[] = ['Large Composite'];
        const smallComposite: string[] = ['Small Composite'];
        const highFinancialRisk: string[] = ['High-Financial-Risk'];



        catogery.metrics.forEach((cat, cindex) => {

            if (cat.tables.length == 1) {
                const catogeryDetails: MetricDetails = {
                    metricCategoryId: catogery.metricCategoryId,
                    metricCategoryName: catogery.metricCategoryName,
                    name: cat.name,
                    trendsOverTimeColumn: cat.trendsOverTimeColumn,
                    trendsOverTimeMetricTitle: cat.trendsOverTimeMetricTitle,
                    columns: cat.tables[0].columns,
                    metricId: cat.tables[0].metricId,
                    metricName: cat.tables[0].metricName,
                    metricCategory: cat.tables[0].metricCategory,
                };
                metricDetails.push(catogeryDetails);
            } else {
                cat.tables.forEach((data, index) => {
                    if ((catogery.metricCategoryId === 8 || catogery.metricCategoryId === 16 || catogery.metricCategoryId === 4) || index == 0) {
                        const catogeryDetails: MetricDetails = {
                            metricCategoryId: catogery.metricCategoryId,
                            metricCategoryName: catogery.metricCategoryName,
                            name: cat.name,
                            trendsOverTimeColumn: cat.trendsOverTimeColumn,
                            trendsOverTimeMetricTitle: cat.trendsOverTimeMetricTitle,
                            columns: data.columns,
                            metricId: data.metricId,
                            metricName: data.metricName,
                            metricCategory: data.metricCategory,
                        };
                        metricDetails.push(catogeryDetails);

                    } else {
                        metricDetails[cindex].columns.push(...data.columns)
                    }
                })
            }

            cat.tables.forEach(data => {
                medianLatest.push(data.medianLatest);
                sicComposite.push(data.sicCompositeLatest);
                largeComposite.push(data.largeCompositeLatest);
                smallComposite.push(data.smallCompositeLatest);
                highFinancialRisk.push(data.highFinancialRiskLatest);

                if (data.showAverageValues) {
                    medianLatest.push(data.medianAverage);
                    sicComposite.push(data.sicCompositeAvergae);
                    largeComposite.push(data.largeCompositeAverage);
                    smallComposite.push(data.smallCompositeAverage);
                    highFinancialRisk.push(data.highFinancialRiskAverage);
                }
            });
        });

        const flatenMtricData: FlattenMteric = {
            metricDetails: metricDetails,
            metricData: [medianLatest, sicComposite,
                largeComposite,
                smallComposite,
                highFinancialRisk
            ]
        }


        flatCatogery.metricCategoryId = catogery.metricCategoryId;
        flatCatogery.metricCategoryName = catogery.metricCategoryName;
        flatCatogery.showCompositeLabels = catogery.showCompositeLabels;
        if (metricDetails?.length > 0) {
            flatCatogery.columnCount = metricDetails[0].columns.length;
        }
        flatCatogery.metrics = flatenMtricData

        return flatCatogery;

    }


    private static mergeTwoMetricObjects(metricOne: FlatenMtericCategory, metricTwo: FlatenMtericCategory, categoryName: string) {
        metricTwo.metrics.metricData.forEach(s => s.splice(0, 1));
        const metrics: FlattenMteric = {
            metricDetails: [...metricOne.metrics.metricDetails, ...metricTwo.metrics.metricDetails],
            metricData: metricOne.metrics.metricData.map((data, index) => [...data, ...metricTwo.metrics.metricData[index]])
        }

        const FlattenMetricData: FlatenMtericCategory = {
            metricCategoryId: metricOne.metricCategoryId,
            metricCategoryName: categoryName,
            showCompositeLabels: metricOne.showCompositeLabels,
            columnCount: metricOne.columnCount,
            metrics: metrics
        }



        return FlattenMetricData


    }

    // public static getAssumptionData(assumptionData: IndustryAssumptions): IndustryAssumptions {
    //     assumptionData.CostOfEquity.forEach(s => s.SICCompositeLatest = s.SICCompositeLatest ? s.SICCompositeLatest * 100 : 0.00)
    //     assumptionData.CostOfDebt.forEach(s => s.SICCompositeLatest = s.SICCompositeLatest ? s.SICCompositeLatest * 100 : 0.00)
    //     assumptionData.DebtBeta.forEach(s => s.SICCompositeLatest = s.SICCompositeLatest ? s.SICCompositeLatest * 100 : 0.00)

    //     return assumptionData;
    // }






    // private static getPosition(categoryId: Category, changeLayout: boolean, position: number): number {
    //     if (categoryId === Category.EquityValuationMultiples && changeLayout) {
    //         return 2;
    //     }

    //     if (categoryId === Category.EnterpriseValuationMultiples && changeLayout) {
    //         return 1;
    //     }

    //     return position;
    // }

    // private static showCompositeLabels(categoryId: Category, changeLayout: boolean, showCompositeLabels: boolean): boolean {
    //     if (categoryId === Category.EquityValuationMultiples && changeLayout) {
    //         return false;
    //     }

    //     if (categoryId === Category.EnterpriseValuationMultiples && changeLayout) {
    //         return true;
    //     }

    //     return showCompositeLabels;
    // }

}
