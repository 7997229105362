import { NgModule } from '@angular/core';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DialogModule } from 'primeng/dialog';
import { CommonModules } from '../_config/common-modules';

// Services
import { QueryBuilderService } from './data/query-builder.service';
import { ExcelExportService } from './exports/summary-export/excel-export.service';

import { OperandUtility } from './studies/operand.utility';

// Components
import { EstimateComponent } from './estimate.component';
import { InputsComponent } from './inputs.component';
import { ResultsComponent } from './results.component';
import { StudiesComponent } from './studies.component';

import { CrspBuildupComponent } from './studies/crsp/crsp-buildup.component';
import { CrspCapmComponent } from './studies/crsp/crsp-capm.component';
import { CrspComponent } from './studies/crsp/crsp.component';
import { HfrBuildupComponent } from './studies/hfr/hfr-buildup.component';
import { HfrCapmComponent } from './studies/hfr/hfr-capm.component';
import { HfrComponent } from './studies/hfr/hfr.component';
import { RiskBuildupOneComponent } from './studies/risk/risk-buildup1.component';
import { RiskBuildupTwoComponent } from './studies/risk/risk-buildup2.component';
import { RiskBuildupThreeComponent } from './studies/risk/risk-buildup3.component';
import { RiskCapmComponent } from './studies/risk/risk-capm.component';
import { RiskComponent } from './studies/risk/risk.component';

import { RangeChartComponent } from './results/range-chart.component';
import { SummaryComponent } from './results/summary.component';
import { WaccComponent } from './results/wacc.component';

// Entry components
import { CompanyListComponent } from './company-list/company-list.component';
import { BenchmarkingExcelExportService } from './exports/benchmarkingexcel-export.service';
import { ChartFullSizeModalComponent } from './modals/chart-full-size-modal.component';
import { ResetEstimateModalComponent } from './modals/reset-estimate-modal.component';
import { SelectIndustryModalComponent } from './modals/select-industry-modal.component';
import { BetaEditorCompanyListComponent } from './studies/_editors/beta-editor-company-list.component';
import { BetaEditorComponent } from './studies/_editors/beta-editor.component';
import { Buildup3PortfolioSelectorComponent } from './studies/_editors/buildup3-portfolio-selector.component';
import { EquityRiskAdjustmentEditorComponent } from './studies/_editors/equity-risk-adjustment-editor.component';
import { EquityRiskPremiumEditorComponent } from './studies/_editors/equity-risk-premium-editor.component';
import { IndustryRiskEditorComponent } from './studies/_editors/industry-risk-editor.component';
import { PortfolioSelectorComponent } from './studies/_editors/portfolio-selector.component';
import { RiskFreeRateEditorComponent } from './studies/_editors/risk-free-rate-editor.component';
import { SizePremiumEditorComponent } from './studies/_editors/size-premium-editor.component';
import { ZscoreEditorComponent } from './studies/_editors/zscore-editor.component';
import { CrspDecileComponent } from './studies/crsp/data-table/crsp-decile.component';
import { ReleverModalComponent } from './studies/relever-modal.component';
import { ComparativeRiskComponent } from './studies/risk/comparative-risk.component';
import { RiskTableComponent } from './studies/risk/risk-table.component';
import { IndustryDataSharingService } from './studies/service/industry-data-sharing.service';
import { SICToGICSMappingComponent } from './sic-to-gics-mapping/sic-to-gics-mapping.component';

export const EstimateDefinition: NgModule = {
    declarations: [
        EstimateComponent,
        InputsComponent,
        StudiesComponent,
        ResultsComponent,
        CrspComponent,
        CrspCapmComponent,
        CrspBuildupComponent,
        RiskComponent,
        RiskCapmComponent,
        RiskFreeRateEditorComponent,
        BetaEditorComponent,
        EquityRiskPremiumEditorComponent,
        SizePremiumEditorComponent,
        EquityRiskAdjustmentEditorComponent,
        IndustryRiskEditorComponent,
        PortfolioSelectorComponent,
        RiskBuildupOneComponent,
        RiskBuildupTwoComponent,
        RiskBuildupThreeComponent,
        Buildup3PortfolioSelectorComponent,
        ReleverModalComponent,
        HfrComponent,
        HfrCapmComponent,
        HfrBuildupComponent,
        ZscoreEditorComponent,
        ResetEstimateModalComponent,
        RangeChartComponent,
        ComparativeRiskComponent,
        SummaryComponent,
        WaccComponent,
        CrspDecileComponent,
        RiskTableComponent,
        CompanyListComponent,
        SelectIndustryModalComponent,
        BetaEditorCompanyListComponent,
        ChartFullSizeModalComponent,
        SICToGICSMappingComponent
    ],
    entryComponents: [
        RiskFreeRateEditorComponent,
        BetaEditorComponent,
        EquityRiskPremiumEditorComponent,
        SizePremiumEditorComponent,
        EquityRiskAdjustmentEditorComponent,
        IndustryRiskEditorComponent,
        PortfolioSelectorComponent,
        Buildup3PortfolioSelectorComponent,
        ZscoreEditorComponent,
        ReleverModalComponent,
        ResetEstimateModalComponent,
        ComparativeRiskComponent,
        CrspDecileComponent,
        RiskTableComponent,
        CompanyListComponent,
        SelectIndustryModalComponent,
        BetaEditorCompanyListComponent,
        ChartFullSizeModalComponent
    ],
    imports: [
        ...CommonModules,
        ExcelExportModule,
        DialogModule
    ],
    providers: [
        OperandUtility,
        ExcelExportService,
        BenchmarkingExcelExportService,
        QueryBuilderService,
        IndustryDataSharingService
    ]
};

@NgModule(EstimateDefinition)
export class EstimateModule { }
