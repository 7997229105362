import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ngbDateStructToString, Spinner } from '@concurrency/angular';
import { ContextualString } from '@concurrency/core';
import { NgbDatepickerConfig, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MetricInputs } from 'src/app/_api/responses/metric-inputs.response';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { MsciReturns } from 'src/app/_api/responses/msci-returns.response';
import { SubscriptionType, UserResponse } from 'src/app/_api/responses/user.response';
import { CommonDate } from 'src/app/_navigator/common/date-struct';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { Help } from 'src/app/_navigator/help/help.model';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
// import { ChartFullSizeModalComponent } from 'src/app/estimate/modals/chart-full-size-modal.component';
import { BetaService } from '../beta.service';
import { BetaComparbleCompanyRequest, BetaComparbleCompanyResults, BetaToT, CompanyReturnsRequest, CompanyselectedChangesResults, GeneralInputs, ReleveredBetas, ReleveredInputs, Statistics, UnleveringTaxRateInputs } from '../beta.types';
import { BetaModuleExcelExportService } from '../betamoduleexcel-export.service';
import { ComparableCompanyModalComponent } from '../comparable-company-modal/comparable-company-modal.component';
import { BetaTrendsOverTimeComponent } from '../beta-trends-over-time/beta-trends-over-time.component';

@Component({
    selector: 'beta-home',
    templateUrl: './beta-home.component.html',
    styleUrls: ['./beta-home.component.scss']
})
export class BetaHomeComponent implements OnInit, OnDestroy {
    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;
    public notifier = new Subject();
    public valuationDate: Date = new Date();
    public dialogHeader = "Performance Indices";
    public isValuationupdate = false;
    public lstInactiveCompanies: any[] = [];
    public changesMade = false;
    public saveEnabled = false;
    public highlightCancelButton = true;
    public tareChart!: any;
    public cancelEnabled = false;
    public diasblevaluationdate = false;
    public editEnabled = true;
    public fullOlsBetaChart: any;
    // public chart!: any;
    public isFullScreenDisplay = false;
    public isCompanyGraphviewlarg = false
    public olsBetaHeader = 'OLS Beta Trends Over Time*'
    public fullSizeChart!: any;
    public minimumDate = -1;
    public maximumComparableCompanies = 20;
    public showchart = false;
    public isBasicSubscriber = true;
    public lineChartOptionsArrayccr: Highcharts.Options[] = [];
    public unleveringtaxRateInputs: UnleveringTaxRateInputs[] = [];
    public previousValuationDate: any;
    // public isFrequencyChanged: boolean = false;
    public frequencyInputReturns: any;
    public inputFrequency: any;
    public lookbackInputReturns: any;
    public inputLookback: any;
    public currencyInputReturns: any;
    public inputCurreny: any;
    public isCurrencyOnChange = false;
    public inputSubjectCompanyValue: any;
    public inputSubjectCompanyId: any;
    public inputMarketIndex: any;
    public inputMarketIndexValue: any;
    public subscriptionLevel!: any;
    public msciReturns: any[] = [];
    public waccOptions: any[] = [];
    public locationOptions: any[] = [];
    public metricChanged = '';
    public exportFileName = '';
    public isDisable = false;
    public isEnterpriseorPro = false;
    public isFromEditSave = false;
    public userdata!: UserResponse;
    public generalInputs: GeneralInputs = {
        ValuationDate: CommonDate.fromDate(new Date()),
        ReturnFrequency: new ContextualString(),
        CurrencyOfWACC: new ContextualString(),
        LookbackPeriod: new ContextualString(),
        SubjectCompanyLocation: new ContextualString(),
        MarketIndex: new ContextualString()
    } as GeneralInputs;
    public comaprableGeneralInputs: GeneralInputs = {} as GeneralInputs;
    public selectedMetrics = [] as any;
    public getselectedMetrics = [] as any;
    public selectedCompanyCurrencyChanges = [] as any;
    public performanceIndicesHelp: Help = HelpText.PerformanceIndices;
    public Help: any = HelpText;
    public marketIndexId!: number;
    public companyHeight = 0;
    public returnFrequencyConfig: CommonInputConfig = {
        name: 'Return Frequency',
        label: 'Return Frequency',
        placeholder: 'Select Return Frequency',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        help: HelpText.ReturnFrequency
    };
    public marketIndexConfig: CommonInputConfig = {
        name: 'Market Index',
        label: 'Market Index',
        placeholder: 'Select Market Index',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        help: HelpText.MarketIndex
    };
    public currencyofwaccconfig: CommonInputConfig = {
        name: 'Currency of WACC',
        label: 'Currency of WACC',
        placeholder: 'Select Currency',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        help: HelpText.CurrencyOfWACC
    };
    public SubjectCompanyLocationconfig: CommonInputConfig = {
        name: 'Subject Company Location',
        label: 'Subject Company Location',
        placeholder: 'Select Subject Company Location',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        help: HelpText.SubjectCompanyLocation
    };
    public Lookbackperiod: CommonInputConfig = {
        name: 'Lookback Period',
        label: 'Lookback Period',
        placeholder: 'Select Lookback Period',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        help: HelpText.LookbackPeriod
    };
    public valuationDateConfig: CommonInputConfig = {
        readonly: true,
        required: true,
        placeholder: 'Select Valuation Date',
        help: HelpText.BetaValuationDate
    };
    public mockSPIndexData: number[] = [];

    public lineChartOptionsArray: Highcharts.Options[] = [];


    //#region  comparableCompanySection

    @ViewChild('cmpnyRow', { static: false }) cmpnyRow!: ElementRef;
    public stickyHeaderHeight!: string
    cmpHeight!: number
    public isBetaStatcollapse!: boolean
    public isunlevinptCollapse!: boolean
    public isUnleverdBetaCollapse!: boolean;

    //#endregion

    //#region  ReleeverdBetas Section
    public isReleverdcollapse = false;
    public betaTypeSelected: any;
    public capitalRateSelected: any;
    public capitalRatelabel: any;
    public taxRateSelected: any;
    public taxRatelabel: any;
    public capitalRatelabelshow = false;
    public taxRatelabelshow = false;
    public helpText = HelpText;
    public currency = '';

    public betaTypeOtions: Suggestion<string>[] = [];


    public releveredBetas: ReleveredBetas = {
        BetaType: new ContextualString(),
        TargetCapital: new ContextualString(),
        TaxRate: new ContextualString()
    } as ReleveredBetas;
    public betaTypeconfig: CommonInputConfig = {
        name: 'Beta Type',
        label: 'Beta Type',
        required: true
    };
    public targetCapitalConfig: CommonInputConfig = {
        name: 'Target Capital Structure (D/TC)',
        label: 'Target Capital Structure (D/TC)',
        required: true,
        mask: '{}%'
    };
    public taxRateConfig: CommonInputConfig = {
        name: 'Tax Rate',
        label: 'Tax Rate',
        required: true,
        mask: '{}%'
    };
    public displayTearSheet: boolean = false;

    //#endregion
    constructor(
        private datepickerConfig: NgbDatepickerConfig,
        public betaService: BetaService,
        private userStore: UserStore,
        private dataStore: DataStore,
        private betaModuleExportService: BetaModuleExcelExportService,
        private spinner: Spinner,
        private primeManager: PrimeManager,
        protected confirmationService: ConfirmationService,
        private renderr: Renderer2
    ) { }

    private BetaModule(): void {
        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.notifier),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((betadata) => {
            if (betadata.user == null || betadata.values == null) {
                return;
            }
            if (betadata.values) {
                this.minimumDate = betadata.values.BtaModuleIndustries;
            }
            this.setValuationDateConfigBasedOnSubscription(this.getValuationDateConfig(ProductType.Bta, betadata), betadata);
        });
    }

    private setValuationDateConfigBasedOnSubscription(config: CommonInputConfig, betaData: any): void {
        const date = moment().toISOString();
        this.valuationDateConfig =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(date),
                ProductType.Bta,
                this.minimumDate,
                betaData.user.subscriptionDetails,
                undefined
            );
        if (config.minimumValue && config.maximumValue && this.valuationDateConfig.minimumValue && this.valuationDateConfig.maximumValue) {
            if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)
                && CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig = {
                    ...this.valuationDateConfig,
                    ...config
                };
            } else if (CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.maximumValue = config.maximumValue;
            } else if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.minimumValue = config.minimumValue;
            }
        }
        // if (this.isBasicSubscriber) {
        //     const today = new Date();
        //     const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
        //     const twoYearsAgoNgbDateStruct: number | NgbDateStruct = { year: twoYearsAgo.getFullYear(), month: twoYearsAgo.getMonth() + 1, day: twoYearsAgo.getDate() + 1 };
        //     this.valuationDateConfig.minimumValue = twoYearsAgoNgbDateStruct;
        // }

        this.subscriptionLevel = betaData.user.subscriptionDetails.find((x: any) => x.productType === 'Bta');
        if (this.valuationDateConfig.maximumValue && this.subscriptionLevel.level === 'Trial') {
            this.generalInputs.ValuationDate = CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct);
        }
    }


    private getValuationDateConfig(productType: ProductType, betaData: any): CommonInputConfig {
        const defaultValuationDate = moment().toISOString();
        const config =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(defaultValuationDate),
                productType,
                this.minimumDate,
                betaData.user.subscriptionDetails,
                undefined
            );

        return config;
    }

    @HostListener('window:resize', [])
    public onWindowResize(): void {
        const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

        // handle company row height when zoom is >=80 or <=67
        this.companyHeight = 108;
        if (zoom <= 67) {
            this.companyHeight = 68;
        }
    }

    public getCompanyRowHeight(): number {
        const comparableCompaniesRow = document.getElementById('comparable_companies_row');
        if (comparableCompaniesRow != null) {
            // initially set default to company row offsetHeight
            if (this.companyHeight <= 0) {
                this.companyHeight = comparableCompaniesRow.offsetHeight;
            }
        }
        return this.companyHeight;
    }

    @HostListener('window:scroll', [])
    public onWindowScroll(): void {
        const comparableCompanies = document.getElementById('comparable_companies');
        const comparableCompaniesDeleteIcon = document.getElementById('comparable_companies_deleteIcon');
        const comparableCompaniesRow = document.getElementById('comparable_companies_row');
        const observation = document.getElementById('observation');

        // logic for fixed position
        const duplicate = document.getElementById('duplicateComparableCompany');
        let top = 0;
        const upperPageHeight = 5;
        if (comparableCompanies != null) {
            top = comparableCompanies.getBoundingClientRect().top - upperPageHeight;
        }
        const topValue = top + 'px';

        // do not apply position fixed for comparable companies section when it is on top position
        // only apply it when this section remains down in screen.
        if (document.body.scrollLeft > 5 || document.documentElement.scrollLeft > 5) {
            if (duplicate != null) {
                duplicate.classList.add('fixed-column');
                duplicate.style.display = 'inline';
                duplicate.style.top = topValue;
            }
        } else {
            if (duplicate != null) {
                duplicate.style.display = 'none';
            }
        }

        if (document.body.scrollLeft > 44 ||
            document.documentElement.scrollLeft > 44) {
            if (comparableCompanies != null) {
                comparableCompanies.classList.remove('zIndex12');
            }
            if (comparableCompaniesDeleteIcon != null) {
                comparableCompaniesDeleteIcon.classList.remove('zIndex12');
            }
            if (comparableCompaniesRow != null) {
                comparableCompaniesRow.classList.remove('zIndex12');
            }
            if (observation != null) {
                observation.classList.remove('sticky-column');
                observation.classList.add('sticky-column-NozIndex');
            }
        } else {
            if (observation != null) {
                observation.classList.remove('sticky-column-NozIndex');
                observation.classList.add('sticky-column');
            }
        }

        if (document.body.scrollTop > 600 ||
            document.documentElement.scrollTop > 600) {
            if (comparableCompanies != null) {
                comparableCompanies.classList.add('zIndex12');
            }
            if (comparableCompaniesDeleteIcon != null) {
                comparableCompaniesDeleteIcon.classList.add('zIndex12');
            }
            if (comparableCompaniesRow != null) {
                comparableCompaniesRow.classList.add('zIndex12');
            }
        }
    }


    public ngOnInit(): void {
        this.betaService.betaTypeSelected = ''
        this.userStore.user.onceDefined((user) => {
            this.isBasicSubscriber = user.Subscriptions.some((item) => item.Type === "BtaBasic");
        });
        if (!this.betaService.isBetaEstimate) {
            this.betaService.betasUpdated = false;
            this.betaService.setEstimates();
        }
        if (this.betaService.metricInputs.Frequency === null
            || this.betaService.metricInputs.Currency === null
            || this.betaService.metricInputs.LookbackPeriod === null
            || this.betaService.metricInputs.Location === null
            || this.betaService.metricInputs.MarketIndex === null) {
            this.isDisable = true;
        }
        this.BetaModule();
        this.betaService.lookbackPeriodOptions = [];
        this.betaService.returnFrequencyOptions = [];
        this.generalInputs.ValuationDate = CommonDate.fromDate(new Date(new Date().setDate(new Date().getDate() - 2)));
        this.betaService.lookbackPeriodList.forEach((x) => {
            this.betaService.lookbackPeriodOptions.push({
                name: x.value,
                value: x.value,
                type: ComboboxDataType.String
            });
            this.betaService.betalocallookbackOptions = this.betaService.lookbackPeriodOptions;
        });
        this.betaService.returnFrequencyList.forEach((rf: any) => {
            this.betaService.returnFrequencyOptions.push({
                name: rf.value,
                value: rf.value,
                type: ComboboxDataType.String
            });
            this.betaService.betalocalfrequencyOptions = this.betaService.returnFrequencyOptions;
        });
        if (this.betaService.isBetaEstimate && this.betaService.metricInputs.Location != null) {
            // this.betaService.subjectCompanyLocationOptions = [];
            this.locationOptions = this.getSubjectCompanyLocations();
        } else {
            this.betaService.subjectCompanyLocationOptions = this.getSubjectCompanyLocations();
            this.locationOptions = this.betaService.subjectCompanyLocationOptions;
        }
        if (this.betaService.isBetaEstimate && this.betaService.metricInputs.Currency != null) {
            // this.betaService.currencyofwaccoptions = [];
            this.waccOptions = this.getCurrenciesOfWacc();
        } else {
            this.betaService.currencyofwaccoptions = this.getCurrenciesOfWacc();
            this.waccOptions = this.betaService.currencyofwaccoptions;
        }

        if (this.betaService.isBetaEstimate && this.betaService.metricInputs !== null) {
            this.generalInputs.ValuationDate = CommonDate.fromDate(new Date(this.betaService.metricInputs.ValuationDate));
            this.generalInputs.ReturnFrequency.value = this.betaService.metricInputs.Frequency;
            this.generalInputs.CurrencyOfWACC.value = this.betaService.metricInputs.Currency;
            this.generalInputs.LookbackPeriod.value = this.betaService.metricInputs.LookbackPeriod;
            this.generalInputs.SubjectCompanyLocation.value = this.betaService.metricInputs.Location;

            this.betaService.returnFrequencyOptions = this.generalInputs.ReturnFrequency.value !== null ? [] : this.betaService.returnFrequencyOptions;
            // this.betaService.subjectCompanyLocationOptions = this.generalInputs.SubjectCompanyLocation.value !== null ? [] : this.betaService.subjectCompanyLocationOptions;
            // this.betaService.currencyofwaccoptions = this.generalInputs.CurrencyOfWACC.value !== null ? [] : this.betaService.currencyofwaccoptions;
            // this.betaService.marketIndexOptions = this.betaService.marketIndexName !== null ? [] : this.betaService.marketIndexOptions;
            this.betaService.lookbackPeriodOptions = this.generalInputs.LookbackPeriod.value !== null ? [] : this.betaService.lookbackPeriodOptions;
            // this.marketIndexDisable();
            this.betaService.leveredBetasCollapse = true;
            this.betaService.unleveringCollapse = true;
            this.betaService.unleveredBetasCollapse = true;
            this.betaService.releveringCollapse = true;
            this.betaService.isClickableAllowed = false;
            this.betaService.insertTaxRateOptions();
        } else {
            this.betaService.finalSelectedCompanies = [];
            this.betaService.leveredBetas = [];
        }
        if (this.betaService.isBetaEstimate && this.betaService.marketIndexName != null) {
            // this.betaService.currencyofwaccoptions = [];
            this.betaService.betalocalmarketIndexOptions = this.getMarketOptions();
        } else {
            this.marketIndexDisable();
        }

        const date = new Date();
        const ngbDateStruct = { day: date.getUTCDate(), month: date.getUTCMonth(), year: date.getUTCFullYear() };
        this.datepickerConfig.maxDate = ngbDateStruct;
        this.betaService.chart = new Chart({
            chart: {
                height: 300,
                width: 600,
                type: 'spline',
                style: {
                    fontFamily: "nunito-sans"
                }
            },
            rangeSelector: {
                selected: 1
            },
            title: {
                text: ''
            },
            series: [
            ],
            xAxis: {
                categories: [],
                visible: false
            },
            yAxis: {
                visible: false
            },
            credits: {
                enabled: false
            }
        });

        this.betaService.isFormDisable.subscribe((x: any) => {
            this.diasbleForm(x);
        });

        this.userStore.user.onceDefined((user) => {
            this.userdata = user;
            if (this.userdata.Subscriptions.find((x) => x.Type === SubscriptionType.BtaEnterprise || x.Type === SubscriptionType.BtaPro || x.Type === SubscriptionType.BtaBasic)) {
                this.betaService.isEnterpriseorPro = true;
            }

        });
        // this.betaService.insertTaxRateOptions();
        // this.currency = this.betaService.metricInputs.Currency;
        this.loadReleverdOnComponentLoad();

    }

    public getSubjectCompanyLocations(): Suggestion<string>[] {
        const options: Suggestion<string>[] = [];
        const request = this.dataStore.Getcountries();
        request.once(() => {
            this.dataStore.countries.subscribe((countries) => {
                if (countries) {
                    countries.forEach((country) => {
                        options.push({
                            id: country.CountryId,
                            name: country.CountryName,
                            value: country.CountryName,
                            type: ComboboxDataType.String,
                            disabled: this.isBasicSubscriber ? (country.CountryName === 'United States' ? false : true) : false
                        });
                    });
                    if (this.betaService.isBetaEstimate && this.betaService.metricInputs.Location != null) {
                        const country = countries.find((x) => x.CountryName === this.betaService.metricInputs.Location);
                        if (country) {
                            this.generalInputs.SubjectCompanyLocation.id = country.CountryId;
                            this.betaService.getTaxRate(this.generalInputs.SubjectCompanyLocation.id, this.betaService.metricInputs.ValuationDate);
                        }
                    }
                    const index = options.findIndex((x) => x.name === 'United States');
                    const newArray: any = options.splice(index, 1);
                    options.unshift(newArray[0]);
                }
            });
        });
        return options;
    }

    public getCurrenciesOfWacc(): Suggestion<string>[] {
        const options: Suggestion<string>[] = [];
        const currencyRequest = this.dataStore.getCurrencies();
        currencyRequest.once(() => {
            this.dataStore.currencies.subscribe((currencies) => {
                if (currencies) {
                    currencies.forEach((currency) => {
                        options.push({
                            name: currency.CurrencyAcronym + ' - ' + currency.CurrencyName,
                            value: currency.CurrencyAcronym + ' - ' + currency.CurrencyName,
                            type: ComboboxDataType.String,
                            disabled: this.isBasicSubscriber ? (currency.CurrencyName === 'US Dollar' ? false : true) : false
                        });
                    });
                    options.sort((a, b) => a.name.localeCompare(b.name));
                    const topCurrencies = ['GBP - Pound Sterling', 'EUR - Euro', 'USD - US Dollar'];
                    topCurrencies.forEach((currency) => {
                        const index = options.findIndex((x) => x.name === currency);
                        const newArray: any = options.splice(index, 1);
                        options.unshift(newArray[0]);
                    });
                }
            });
        });
        return options;
    }

    public onMarketIndexChange(inputs: any, search?: any): void {
        if (inputs && this.betaService.finalSelectedCompanies.length > 0 && inputs.MarketIndex && (this.inputCurreny !== inputs.CurrencyOfWACC.value || this.inputMarketIndex !== inputs.MarketIndex || this.previousValuationDate !== this.generalInputs.ValuationDate) || this.betaService.isBetaEstimate) {
            this.changesMade = true;
        }
        if (inputs) {
            if (search === 'currency' && inputs.CurrencyOfWACC.value !== undefined && !this.cancelEnabled) {
                const searchTerm = this.generalInputs.CurrencyOfWACC.value ? this.generalInputs.CurrencyOfWACC.value : '';
                if (searchTerm !== '') {
                    this.betaService.currencyofwaccoptions = this.waccOptions.filter((item) => item.value.toLowerCase().startsWith(searchTerm.toLowerCase()));
                }
            }
            // (!this.betaService.isBetaEstimate || this.isGeneralInputsValid())
            if (this.betaService.finalSelectedCompanies.length === 0 && !this.betaService.isBetaEstimate) {
                this.marketIndexDisable();
                // this.getReturns(inputs);
            }
            if (this.isGeneralInputsValid()) {
                this.getReturns(inputs);
            }
            // this.betaService.fullCurrencyName = this.generalInputs.CurrencyOfWACC.value || null;
            this.betaService.metricInputs.ValuationDate = ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0];
            if (this.generalInputs.CurrencyOfWACC !== null && this.generalInputs.CurrencyOfWACC.value !== null) {
                this.betaService.metricInputs.Currency = this.generalInputs.CurrencyOfWACC.value.substring(0, 3);
            }
            this.betaService.marketIndexName = this.generalInputs.MarketIndex.value;
            if (!this.betaService.betasUpdated) {
                this.betaService.updateEstimate();
                this.betaService.locationName = "";
                // this.betaService.metricInputs.Location = null || "";
            }
        }
    }

    public onClick(): void {
        if (this.betaService.isClickableAllowed) {
            this.betaService.currencyofwaccoptions = this.waccOptions;
            this.betaService.subjectCompanyLocationOptions = this.locationOptions;
        }
    }


    public getReturns(inputs: any): void {
        if (inputs.LookbackPeriod.value
            && inputs.ReturnFrequency.value
            && inputs.ValuationDate
            && inputs.MarketIndex.value
            && inputs.CurrencyOfWACC.value
            && inputs.SubjectCompanyLocation.value) {
            const input: MetricInputs = {
                ValuationDate: ngbDateStructToString(inputs.ValuationDate).split('T')[0],
                Frequency: inputs.ReturnFrequency.value,
                Currency: inputs.CurrencyOfWACC.value.substring(0, 3),
                LookbackPeriod: inputs.LookbackPeriod.value,
                Location: inputs.SubjectCompanyLocation.value,
                MarketIndex: inputs.MarketIndex.id
            };
            this.betaService.metricInputs = input;
            this.betaService.marketIndexName = inputs.MarketIndex.value;
            if (!this.isDisable && input.Currency.includes('-')) {
                input.Currency = input.Currency.substring(0, 3);
            }
            if (this.betaService.startSpinner) {
                this.spinner.begin(0);
            }
            const request = this.dataStore.getReturns(input);
            // this.spinner.while(request);
            request.once(() => {
                this.dataStore.returns.subscribe((_returns) => {
                    this.msciReturns = [];
                    if (_returns) {
                        _returns.forEach((data) => {
                            this.msciReturns.push(data);
                        });
                        this.setGraphData(inputs, this.msciReturns);
                        // this.spinner.end();
                    }
                },
                    () => {
                        this.showchart = false;
                    }, () => {
                        this.showchart = false;
                    }
                );
            });

        } else {
            this.showchart = false;
        }
        if (this.betaService.endSpinner) {
            this.spinner.end();
        }


    }


    public onCompanyLocationChange(inputs: any): void {
        if (inputs) {
            this.betaService.metricInputs.ValuationDate = ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0];

            if (inputs.SubjectCompanyLocation.value !== undefined && !this.cancelEnabled) {
                const searchTerm = this.generalInputs.SubjectCompanyLocation.value ? this.generalInputs.SubjectCompanyLocation.value : '';
                if (searchTerm !== '') {
                    this.betaService.subjectCompanyLocationOptions = this.locationOptions.filter((item) => item.value.toLowerCase().startsWith(searchTerm.toLowerCase()));
                }

            }
            if (this.metricChanged !== 'Location') {
                this.betaService.locationName = this.betaService.metricInputs.Location;
            }

            if (!this.cancelEnabled) {
                this.generalInputs.MarketIndex.value = '';
                this.showchart = false;
            }
            this.marketIndexDisable();
            if (this.betaService.finalSelectedCompanies.length === 0) {
                this.getReturns(inputs);
            }
            if (this.cancelEnabled) {
                this.SubjectCompanyLocationconfig.disabled = true;
                this.betaService.subjectCompanyLocationOptions = [];
                this.marketIndexConfig.disabled = true;
                this.betaService.marketIndexOptions = [];
            }

            if (!this.betaService.betasUpdated && this.metricChanged !== 'Location') {
                this.betaService.metricInputs.Location = this.generalInputs.SubjectCompanyLocation.value || '';
                this.betaService.locationName = "";
                this.betaService.updateEstimate();
                // this.betaService.metricInputs.Location = null || "";
                this.metricChanged = 'Location';
            }

        }
    }


    public onFrequencyChange(inputs: any): void {
        this.betaService.metricInputs.ValuationDate = ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0];
        if (inputs && this.betaService.finalSelectedCompanies.length > 0 && this.inputFrequency !== inputs.ReturnFrequency.value || this.betaService.isBetaEstimate) {
            this.changesMade = true;
        }
        if (inputs && inputs.MarketIndex.value && inputs.ReturnFrequency.value !== this.frequencyInputReturns && this.betaService.finalSelectedCompanies.length === 0) {
            this.getReturns(inputs);
            this.frequencyInputReturns = inputs.ReturnFrequency.value;
        }
        this.betaService.metricInputs.Frequency = this.generalInputs.ReturnFrequency.value || '';
        if (!this.betaService.betasUpdated && this.metricChanged !== 'Frequency') {
            this.betaService.updateEstimate();
            this.metricChanged = 'Frequency';
        }
    }

    public seletedCompaniesFrequencyChange(inputFrequencies: any): void {
        if (inputFrequencies) {
            this.spinner.begin(0);
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                company.Frequency = inputFrequencies.ReturnFrequency.value;
            });
            this.spinner.end();
        }
    }
    public seletedCompaniesLookbackPeriodChange(inputLookbackPeriod: any): void {
        if (inputLookbackPeriod) {
            this.spinner.begin(0);
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                company.LookbackPeriod = inputLookbackPeriod.LookbackPeriod.value;
            });
            this.spinner.end();
        }
    }
    public seletedCompaniesCurrencyChange(inputCurrency: any): void {
        if (inputCurrency) {
            this.spinner.begin(0);
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                company.Currency = inputCurrency.CurrencyOfWACC.value;
            });
            this.spinner.end();
        }
    }

    public onLookbackPeriodChange(inputs: any): void {
        this.betaService.metricInputs.ValuationDate = ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0];
        if (inputs && this.betaService.finalSelectedCompanies.length > 0 && this.inputLookback !== inputs.LookbackPeriod.value || this.betaService.isBetaEstimate) {
            this.changesMade = true;
        }
        if (inputs && inputs.MarketIndex.value && inputs.LookbackPeriod.value !== this.lookbackInputReturns && this.betaService.finalSelectedCompanies.length === 0) {
            this.getReturns(inputs);
            this.lookbackInputReturns = inputs.LookbackPeriod.value;
        }
        this.betaService.metricInputs.LookbackPeriod = this.generalInputs.LookbackPeriod.value || '';
        if (!this.betaService.betasUpdated && this.metricChanged !== 'Lookback') {
            this.betaService.updateEstimate();
            this.metricChanged = 'Lookback';
        }

    }
    public marketIndexDisable(): void {
        if (!(this.generalInputs.ValuationDate
            && this.generalInputs.CurrencyOfWACC
            && this.generalInputs.CurrencyOfWACC.value
            && this.generalInputs.SubjectCompanyLocation
            && this.generalInputs.SubjectCompanyLocation.value
        )) {
            this.marketIndexConfig.disabled = true;
            this.betaService.marketIndexOptions.forEach((x) => {
                x.disabledText = '- Disabled';
            });
        } else {
            this.marketIndexConfig.disabled = false;
            this.betaService.marketIndexOptions.forEach((x) => {
                x.disabledText = undefined;
            });
            this.betaService.betalocalmarketIndexOptions = this.betaService.marketIndexOptions;
        }
        this.betaService.marketIndexOptions = this.getMarketOptions();

    }

    public getMarketOptions(): Suggestion<string>[] {
        let options: Suggestion<string>[] = [];
        if (this.generalInputs.ValuationDate && this.generalInputs.CurrencyOfWACC.value && this.generalInputs.SubjectCompanyLocation.value && !this.cancelEnabled) {
            const companyEncoded = encodeURIComponent(this.generalInputs.SubjectCompanyLocation.value);
            const request = this.dataStore.getMetrics(companyEncoded);
            if (this.betaService.startSpinner) {
                this.spinner.begin();
            }
            //this.spinner.while(request);
            request.once(() => {
                this.dataStore.metrics.subscribe((metrics) => {
                    if (metrics) {
                        //  this.betaService.marketIndexOptions = [];
                        metrics.forEach((metric) => {
                            const indexName = this.isBasicSubscriber ? (metric.MSCIIndexName === 'USA' ? 'MSCI ' + metric.MSCIIndexName : metric.MSCIIndexName) : metric.MSCIIndexName;
                            options.push({
                                id: metric.MSCIMetricID,
                                name: indexName,
                                value: indexName,
                                type: ComboboxDataType.String,
                                disabled: this.isBasicSubscriber ? (metric.MSCIIndexName === 'MSCI USA' ? false : true) : false
                            });
                        });
                        if (this.betaService.isBetaEstimate && (this.betaService.marketIndexName !== null || this.betaService.marketIndexName !== "") && !this.isDisable) {
                            const market = metrics.find((x: any) => x.MSCIIndexName === this.betaService.marketIndexName);
                            if (market) {
                                this.generalInputs.MarketIndex.id = market.MSCIMetricID;
                                this.betaService.metricInputs.MarketIndex = this.generalInputs.MarketIndex.id;
                            }
                            this.generalInputs.MarketIndex.value = this.betaService.marketIndexName;
                            this.getReturns(this.generalInputs);
                            const companyRequest: CompanyReturnsRequest = {
                                GVKEY: this.betaService.finalSelectedCompanies.map((beta: BetaComparbleCompanyResults) => beta.GVKEY).filter((value: any, index: any, self: any) => self.indexOf(value) === index),
                                Metrics: this.betaService.metricInputs
                            };
                            this.betaService.getCompanyReturns(companyRequest);
                            if (this.betaService.locationName !== this.generalInputs.SubjectCompanyLocation.value) {
                                this.betaService.subjectCompanyLocationOptions = this.locationOptions;
                                this.betaService.isFormDisable.next(false);
                                this.marketIndexConfig.disabled = false;
                                // this.betaService.metricInputs.Location = this.generalInputs.SubjectCompanyLocation.value || "";
                            } else {
                                this.betaService.isFormDisable.next(true);
                            }
                            this.editEnabled = true;
                            this.betaService.isClickableAllowed = false;
                            options = (this.betaService.marketIndexName !== null || this.betaService.marketIndexName !== "") && options.length === 0 ? [] : options;
                        }
                    }
                    if (options.length === 0 || (this.betaService.isBetaEstimate && !this.betaService.editClicked)) {
                        this.marketIndexConfig.disabled = true;
                        options.forEach((x) => {
                            x.disabledText = undefined;
                        });
                    } else {
                        this.marketIndexConfig.disabled = false;
                    }
                });
                if (!this.betaService.isBetaEstimate) {
                    this.spinner.end();
                }
            });

        }
        return options;
    }
    public isGeneralInputsValid(): boolean {
        // return true
        if (this.generalInputs.ValuationDate
            && this.generalInputs.CurrencyOfWACC
            && this.generalInputs.CurrencyOfWACC.value
            && this.generalInputs.SubjectCompanyLocation
            && this.generalInputs.SubjectCompanyLocation.value
            && this.generalInputs.LookbackPeriod
            && this.generalInputs.LookbackPeriod.value
            && this.generalInputs.ReturnFrequency
            && this.generalInputs.ReturnFrequency.value
            && this.generalInputs.MarketIndex
            && this.generalInputs.MarketIndex.value
            && !this.diasblevaluationdate
            && this.betaService.finalSelectedCompanies.length < this.maximumComparableCompanies
        ) {
            return true;
        } else {
            return false;
        }
    }
    public addComparableBeta(): void {
        this.betaService.startSpinner = true;
        this.betaService.endSpinner = true;
        const betaComparbleCompanyRequest: BetaComparbleCompanyRequest = this.assigneComparableObject();
        this.betaService.betaComparbleCompanyRequest = betaComparbleCompanyRequest;
        if (this.betaService.filteredCompanyList.length <= 0 ||
            (this.comaprableGeneralInputs
                && JSON.stringify(this.generalInputs) !== JSON.stringify(this.comaprableGeneralInputs)
                && this.betaService.finalSelectedCompanies.length <= 0) || this.betaService.isSearchClicked) {
            this.betaService.filteredCompanyList = [];
            // this.betaService.AssigneSelectedCompanies().once(() => {
            this.openComparableCompanyModal();
            // });
            // this.betaService.getComparbleCompanyList().once(() => {
            //     this.openComparableCompanyModal();
            // });

        } else {
            if (this.betaService.generalInputsModified) {
                this.betaService.filteredCompanyList = [];
                // this.betaService.AssigneSelectedCompanies().once(() => {
                this.openComparableCompanyModal();
                // })
                // this.betaService.getComparbleCompanyList().once(() => {
                //     this.openComparableCompanyModal();
                // });
                // this.openComparableCompanyModal();
            } else {
                // this.betaService.AssigneSelectedCompanies().once(() => {
                this.openComparableCompanyModal();
                // });
            }

        }
        //   this.betaService.subjectCompanyLocationOptions = this.locationOptions;
        window.scrollTo(0, 0);
    }

    public assigneComparableObject(): BetaComparbleCompanyRequest {

        const betaComparbleCompanyRequest: BetaComparbleCompanyRequest = {
            valuationDate: ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0],
            isMonthly: this.generalInputs.ReturnFrequency.value === 'Monthly' ? true : false,
            currency: this.generalInputs.CurrencyOfWACC.value !== null ? this.generalInputs.CurrencyOfWACC.value.substring(0, 3) : 'USD',
            lookbackPeriod: this.generalInputs.LookbackPeriod.value !== null ? this.generalInputs.LookbackPeriod.value : '1-Year',
            assets: null,
            assetsOperatorName: null,
            companyName: null,
            EBITDA: null,
            EBITDAOperatorName: null,
            industry: null,
            keyWords: null,
            debt: null,
            debtOperatorName: null,
            marketCap: null,
            marketCapOperatorName: null,
            observations: null,
            observationsOperatorName: null,
            PAGE: 1,
            sales: null,
            salesOperatorName: null,
            SIZE: 100,
            ticker: null,
            incorporationCountry: this.isBasicSubscriber ? 'United States' : null,
            headquarterCountry: null,
            exchange: null,
            website: null

        };

        return betaComparbleCompanyRequest;
    }

    public openComparableCompanyModal(): void {
        this.betaService.setPageCount()
        this.primeManager.openDialog(ComparableCompanyModalComponent,
            {
                generalInputs: this.generalInputs,
                companies: this.betaService.finalSelectedCompanies
            });
    }
    public setGraphData(inputs: any, returns: MsciReturns[]): void {
        let marketReturns: any[] = [];
        let dateArray: string[] = [];

        returns.forEach((msciReturn) => {
            dateArray = [...dateArray, moment(msciReturn.DataAsOf).format('MM/DD/YYYY')];
            marketReturns = [...marketReturns, msciReturn.MetricValue];
        });
        this.betaService.betasAsOf = dateArray[dateArray.length - 1];

        const marketIndex = inputs.MarketIndex.value;
        this.betaService.chart = new Chart({
            chart: {
                height: 300,
                width: 600,
                type: 'line',
                style: {
                    fontFamily: "nunito-sans"
                }
            },
            title: {
                text: ''
            },
            series: [
                {
                    type: 'line',
                    name: marketIndex,
                    color: 'rgb(0, 48, 87)',
                    data: [...marketReturns],
                    marker: {
                        enabled: false
                    },
                    dashStyle: 'Dot'
                    // zones: [
                    //     { dashStyle: 'Dot' } //this is only for line not for legend
                    // ]

                }
            ],
            xAxis: {
                categories: [...dateArray],
                gridLineWidth: 0,
                gridLineColor: 'transparent',
                tickPositions: [0, dateArray.length - 1]
            },
            yAxis: {
                title: {
                    text: ''
                },
                tickInterval: 0.1,
                gridLineWidth: 0,
                gridLineColor: 'transparent'
                // min: 1,
                // startOnTick: true
            },
            tooltip: {
                enabled: true
            },
            credits: {
                enabled: false
            }
        });
        if (this.betaService.chart.options.series.data !== null) {
            this.showchart = true;
        }
    }
    public diasbleForm(isDisable: boolean): void {
        if (isDisable) {
            this.currencyofwaccconfig.disabled = true;
            this.returnFrequencyConfig.disabled = true;
            this.SubjectCompanyLocationconfig.disabled = true;
            this.marketIndexConfig.disabled = true;
            this.Lookbackperiod.disabled = true;
            this.valuationDateConfig.disabled = true;
            this.betaService.marketIndexOptions.forEach((x) => {
                x.disabledText = '- Disabled';
            });
            this.betaService.returnFrequencyOptions.forEach((x) => {
                x.disabledText = '- Disabled';
            });
            this.betaService.subjectCompanyLocationOptions.forEach((x) => {
                x.disabled = true;
                x.disabledText = '- Disabled';
            });
            this.betaService.lookbackPeriodOptions.forEach((x) => {
                x.disabledText = '- Disabled';
            });
            this.betaService.currencyofwaccoptions.forEach((x) => {
                x.disabled = true;
                x.disabledText = '- Disabled';
            });
            if (!this.isFromEditSave) {
                this.inputCurreny = this.generalInputs.CurrencyOfWACC.value;
                this.inputFrequency = this.generalInputs.ReturnFrequency.value;
                this.inputLookback = this.generalInputs.LookbackPeriod.value;
                this.inputSubjectCompanyId = this.generalInputs.SubjectCompanyLocation.id;
                this.inputSubjectCompanyValue = this.generalInputs.SubjectCompanyLocation.value;
                this.inputMarketIndex = this.generalInputs.MarketIndex;
                this.inputMarketIndexValue = this.generalInputs.MarketIndex.value;
                this.previousValuationDate = this.generalInputs.ValuationDate;
            }

        } else {
            this.currencyofwaccconfig.disabled = false;
            this.returnFrequencyConfig.disabled = false;
            this.SubjectCompanyLocationconfig.disabled = false;
            this.marketIndexConfig.disabled = false;
            this.Lookbackperiod.disabled = false;
            this.valuationDateConfig.disabled = false;
            // if (this.cancelEnabled || this.diasblevaluationdate) {
            //     this.valuationDateConfig.disabled = true;
            // } else {
            //     this.valuationDateConfig.disabled = false;
            // }
            this.betaService.marketIndexOptions.forEach((x) => {
                x.disabledText = undefined;
            });
            this.betaService.returnFrequencyOptions.forEach((x) => {
                x.disabledText = undefined;
            });
            this.betaService.subjectCompanyLocationOptions.forEach((x) => {
                x.disabled = false;
                x.disabledText = undefined;
            });
            this.betaService.lookbackPeriodOptions.forEach((x) => {
                x.disabledText = undefined;
            });
            this.betaService.currencyofwaccoptions.forEach((x) => {
                x.disabled = false;
                x.disabledText = undefined;
            });
        }
    }
    public isComarbleCompanyFormChanged(): void {
        if (this.betaService.finalSelectedCompanies.length <= 0) {
            this.comaprableGeneralInputs = JSON.parse(JSON.stringify(this.generalInputs));
        }
    }

    public EditGenralInputs(): void {
        this.betaService.startSpinner = true;
        this.betaService.endSpinner = true;
        this.betaService.editClicked = true;
        this.isFromEditSave = false;
        this.changesMade = false;
        this.saveEnabled = true;
        this.editEnabled = false;
        this.cancelEnabled = false;
        this.diasblevaluationdate = true;
        this.betaService.isClickableAllowed = true;
        if (this.isBasicSubscriber) {
            this.betaService.subjectCompanyLocationOptions = this.getSubjectCompanyLocations();
            this.locationOptions = this.betaService.subjectCompanyLocationOptions;
        } else {
            this.betaService.subjectCompanyLocationOptions = this.locationOptions;

        }
        if (this.isBasicSubscriber) {
            this.betaService.currencyofwaccoptions = this.getCurrenciesOfWacc();
            this.waccOptions = this.betaService.currencyofwaccoptions;
        } else {
            this.betaService.currencyofwaccoptions = this.waccOptions;
        }
        this.betaService.lookbackPeriodOptions = this.betaService.betalocallookbackOptions;
        this.betaService.marketIndexOptions = this.betaService.betalocalmarketIndexOptions;
        this.betaService.returnFrequencyOptions = this.betaService.betalocalfrequencyOptions;
        this.diasbleForm(false);
        this.metricChanged = '';
    }
    public showChartFullSize(): void {

        this.fullSizeChart = new Chart({
            chart: {
                height: 500,
                width: 1200,
                type: 'line',
            },


            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: this.betaService.chart.options.series,
            xAxis: this.betaService.chart.options.xAxis,
            yAxis: this.betaService.chart.options.yAxis
        });
        this.isFullScreenDisplay = !this.isFullScreenDisplay;

    }
    public exportToExcel(component: ExcelExportComponent): void {
        this.exportFileName = 'company-beta-summary.xlsx';
        this.betaModuleExportService.save(component);
    }
    public ngOnDestroy(): void {
        this.betaService.UserHiddenCompanies = [];
        this.notifier.next();
        this.notifier.complete();
    }

    public getSelectedCompanyChanges(companyselectedChangesRequest: any): void {
        if (companyselectedChangesRequest.GVKEY) {
            const request = this.dataStore.getCompanySelectedChanges(companyselectedChangesRequest);
            this.spinner.while(request);
            request.once((results: CompanyselectedChangesResults[] | undefined) => {
                this.getselectedMetrics = results;
                this.selectedMetrics = this.betaService.finalSelectedCompanies.filter((x: any) => !this.getselectedMetrics.find((cmp: any) => x.GVKEY === cmp.GVKEY));
                if (this.selectedMetrics.length > 0) {
                    this.isValuationupdate = true;
                } else {
                    this.onUserProceeds();
                }

            });
        }
    }

    public setFinalEditCompanies(): void {
        this.selectedMetrics.forEach((company: any) => {
            this.betaService.removeCompanyFromParentComponent(false, company);
        });

        this.betaService.finalSelectedCompanies.map((company: any) => {
            const gvkEyExist = this.getselectedMetrics.find((x: { GVKEY: any; }) => x.GVKEY === company.GVKEY);
            if (gvkEyExist) {
                company.Observations = gvkEyExist.Observations;
            }
        });
        this.onUserProceeds();
    }

    public generalInputsEditSave(inputs: any): void {
        this.betaService.gvKeys = [];
        const BetaInputs: MetricInputs = {
            ValuationDate: ngbDateStructToString(inputs.ValuationDate).split('T')[0],
            Frequency: inputs.ReturnFrequency.value,
            Currency: inputs.CurrencyOfWACC.value !== null ? inputs.CurrencyOfWACC.value.substring(0, 3) : 'USD',
            LookbackPeriod: inputs.LookbackPeriod.value,
            Location: inputs.SubjectCompanyLocation.value,
            MarketIndex: inputs.MarketIndex.id
        };
        if (this.betaService.finalSelectedCompanies.length > 0) {

            const Gvkeys: any[] = [];
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                Gvkeys.push(company.GVKEY);
            });
            const CompanyselectedChangesRequests: any = {
                GVKEY: Gvkeys,
                Metrics: BetaInputs
            };
            this.betaService.gvKeys = Gvkeys;
            this.getSelectedCompanyChanges(CompanyselectedChangesRequests);
            this.betaService.betasUpdated = false;
        }
        this.betaService.updateEstimate();
        this.saveEnabled = false;
        this.changesMade = false;
        this.editEnabled = true;
        this.diasblevaluationdate = false;
        this.betaService.isClickableAllowed = false;
        this.marketIndexConfig.disabled = false;
        this.betaService.returnFrequencyOptions = [];
        this.betaService.subjectCompanyLocationOptions = [];
        this.betaService.currencyofwaccoptions = [];
        this.betaService.marketIndexOptions = [];
        this.betaService.lookbackPeriodOptions = [];
        this.isFromEditSave = true;
        this.betaService.isFormDisable.next(true);
        // this.diasbleForm(true, true);
        this.betaService.editClicked = false;
        this.metricChanged = '';
    }

    public generalInputsEditCancel(): void {
        this.generalInputs.ValuationDate = this.previousValuationDate;
        this.generalInputs.CurrencyOfWACC.value = this.inputCurreny;
        this.generalInputs.LookbackPeriod.value = this.inputLookback;
        this.generalInputs.ReturnFrequency.value = this.inputFrequency;
        this.generalInputs.SubjectCompanyLocation.id = this.inputSubjectCompanyId;
        this.generalInputs.SubjectCompanyLocation.value = this.inputSubjectCompanyValue;
        this.generalInputs.MarketIndex = this.inputMarketIndex;
        this.generalInputs.MarketIndex.value = this.inputMarketIndexValue;
        this.betaService.returnFrequencyOptions = [];
        this.betaService.subjectCompanyLocationOptions = [];
        this.betaService.currencyofwaccoptions = [];
        this.betaService.marketIndexOptions = [];
        this.betaService.lookbackPeriodOptions = [];
        this.editEnabled = true;
        this.changesMade = false;
        this.saveEnabled = false;
        this.cancelEnabled = true;
        this.diasblevaluationdate = false;
        this.betaService.isClickableAllowed = false;
        this.isFromEditSave = false;
        this.diasbleForm(true);
        this.betaService.updateEstimate();
        this.metricChanged = '';
    }

    public onProceed(isProceed: boolean): void {
        this.isValuationupdate = false;
        if (isProceed) {
            this.setFinalEditCompanies();
        } else {
            this.generalInputsEditCancel();
        }
    }

    // On Click Confirm
    public onUserProceeds(): void {
        this.diasblevaluationdate = false;
        this.editEnabled = true;
        this.betaService.leveredBetas = [];
        this.betaService.betaStatistics = [];
        this.betaService.unleveredBetas = [];
        this.betaService.unleveringInputs = [];
        this.betaService.unleveringTaxRateValues = [];
        this.betaService.releveredInputs = {} as ReleveredInputs;
        this.betaService.leveredStatistics = {} as Statistics;
        this.betaService.unleveredStatistics = {} as Statistics;
        this.betaService.releveredStatistics = {} as Statistics;
        this.betaService.taxRateSelected = '';
        this.betaService.taxRateSelectedLabel = '';
        this.betaService.capitalRateSelected = '';
        this.betaService.capitalRateSelectedLabel = '';
        this.betaService.betaTypeSelected = '';
        this.betaService.originalTargetCapitalStructure = '';
        this.betaService.minimumSummaryValue = '';
        this.betaService.firstQuartileSummaryValue = '';
        this.betaService.averageSummaryValue = '';
        this.betaService.medianSummaryValue = '';
        this.betaService.thirdQuartileSummaryValue = '';
        this.betaService.maximumSummaryValue = '';
        this.betaService.leveredminimumSummaryValue = '';
        this.betaService.leveredfirstQuartileSummaryValue = '';
        this.betaService.leveredaverageSummaryValue = '';
        this.betaService.leveredmedianSummaryValue = '';
        this.betaService.leveredthirdQuartileSummaryValue = '';
        this.betaService.leveredmaximumSummaryValue = '';
        this.betaService.unleveredMinimumSummaryValue = '';
        this.betaService.unleveredFirstQuartileSummaryValue = '';
        this.betaService.unleveredAverageSummaryValue = '';
        this.betaService.unleveredMedianSummaryValue = '';
        this.betaService.unleveredThirdQuartileSummaryValue = '';
        this.betaService.unleveredMaximumSummaryValue = '';
        this.betaService.isClickableAllowed = false;
        const GVKeys: any[] = [];
        this.unleveringtaxRateInputs = [];
        this.betaService.returnFrequencyOptions = [];
        this.betaService.subjectCompanyLocationOptions = [];
        this.betaService.currencyofwaccoptions = [];
        this.betaService.lookbackPeriodOptions = [];
        this.betaService.finalSelectedCompanies.forEach((company: any) => {
            GVKeys.push(company.GVKEY);
        });
        const MetricInputValues: MetricInputs = {
            ValuationDate: ngbDateStructToString(this.generalInputs.ValuationDate).split('T')[0],
            Frequency: this.generalInputs.ReturnFrequency.value === 'Monthly' ? 'Monthly' : 'Weekly',
            Currency: this.generalInputs.CurrencyOfWACC.value !== null ? this.generalInputs.CurrencyOfWACC.value.substring(0, 3) : 'USD',
            LookbackPeriod: this.generalInputs.LookbackPeriod.value !== null ? this.generalInputs.LookbackPeriod.value : '1-Year',
            Location: this.generalInputs.SubjectCompanyLocation.value !== null ? this.generalInputs.SubjectCompanyLocation.value : 'MSCI USA',
            MarketIndex: this.generalInputs.MarketIndex.id !== null ? this.generalInputs.MarketIndex.id : 984000
        };
        const companyReturnsRequests: CompanyReturnsRequest = {
            GVKEY: GVKeys,
            Metrics: MetricInputValues
        };
        this.betaService.metricInputs = MetricInputValues;
        this.betaService.marketIndexName = this.generalInputs.MarketIndex.value;
        const valuationDate = CommonDate.fromStruct(this.generalInputs.ValuationDate).asString();
        const metricRequest = this.dataStore.getReturns(MetricInputValues);
        this.spinner.while(metricRequest);
        metricRequest.once(() => {
            this.dataStore.metrics.subscribe((metrics) => {
                if (metrics) {
                    this.betaService.marketIndexOptions = [];
                    metrics.forEach((metric) => {
                        const indexName = this.isBasicSubscriber ? (metric.MSCIIndexName === 'USA' ? 'MSCI ' + metric.MSCIIndexName : metric.MSCIIndexName) : metric.MSCIIndexName;
                        this.betaService.marketIndexOptions.push({
                            id: metric.MSCIMetricID,
                            name: indexName,
                            value: indexName,
                            type: ComboboxDataType.String,
                            disabled: this.isBasicSubscriber ? (metric.MSCIIndexName === 'MSCI USA' ? false : true) : false
                        });
                    });
                    this.betaService.betalocalmarketIndexOptions = this.betaService.marketIndexOptions;
                }
                if (this.betaService.marketIndexOptions.length === 0) {
                    this.marketIndexConfig.disabled = true;
                    this.betaService.marketIndexOptions.forEach((x) => {
                        x.disabledText = undefined;
                    });
                }
            });
            if (this.inputCurreny !== this.generalInputs.CurrencyOfWACC.value ||
                this.inputFrequency !== this.generalInputs.ReturnFrequency.value ||
                this.inputLookback !== this.generalInputs.LookbackPeriod.value ||
                this.inputSubjectCompanyValue !== this.generalInputs.SubjectCompanyLocation.value ||
                this.inputMarketIndex !== this.generalInputs.MarketIndex || this.previousValuationDate !== this.generalInputs.ValuationDate) {
                const request = this.dataStore.getCompanySelectedCurrencyChanges(companyReturnsRequests);
                this.spinner.while(request);
                request.once((results: BetaComparbleCompanyResults[] | undefined) => {
                    this.selectedCompanyCurrencyChanges = results;
                    this.selectedCompanyCurrencyChanges.forEach((selectedCompany: any) => {
                        const company = this.betaService.finalSelectedCompanies.filter((x: any) => x.GVKEY === selectedCompany.GVKEY);
                        company[0].Dataasof = selectedCompany.Dataasof;
                        company[0].mAsOf = selectedCompany.mAsOf;
                        company[0].Currency = selectedCompany.Currency;
                        company[0].Sales = selectedCompany.Sales;
                        company[0].Assets = selectedCompany.Assets;
                        company[0].EBITDA = selectedCompany.EBITDA;
                        company[0].MarketCap = selectedCompany.MarketCap;
                        company[0].TotalDebt = selectedCompany.TotalDebt;
                        company[0].Observations = selectedCompany.Observations;
                        company[0].HeadquarterCountry = selectedCompany.HeadquarterCountry;
                        company[0].IncorporationCountry = selectedCompany.IncorporationCountry;
                    });
                    this.betaService.finalSelectedCompanies.forEach((company: any) => {
                        GVKeys.push(company.GVKEY);
                        this.unleveringtaxRateInputs.push({
                            GVKEY: company.GVKEY,
                            CountryCode: company.IncorporationCountry,
                            DataAsOf: CommonDate.fromStruct(this.generalInputs.ValuationDate).asDate()
                        });
                    });
                    if (this.generalInputs.SubjectCompanyLocation.id) {
                        this.betaService.getTaxRateList(this.unleveringtaxRateInputs);
                        this.betaService.getTaxRate(this.generalInputs.SubjectCompanyLocation.id, valuationDate);
                    }

                    this.betaService.getLeveredBetas(companyReturnsRequests, this.betaService.unleveredBetas, this.betaService.unleveringInputs);
                    // this.betaService.getBetaStatistics(companyReturnsRequests);
                    this.betaService.getCompanyReturns(companyReturnsRequests);
                }
                );
                this.saveEnabled = false;
                this.changesMade = false;
                this.betaService.generalInputsModified = true;
                this.betaService.marketIndexOptions = [];
                // this.betaService.subjectCompanyLocationOptions = this.locationOptions;
                // this.betaService.currencyofwaccoptions = this.waccOptions;
                this.isFromEditSave = false;
                this.diasbleForm(true);
            }
        });

    }

    public onClickCompanyName(company: any): void {
        this.betaService.isCompanyDisplay = false;
        this.displayTearSheet = true;
        this.betaService.betaComparbleCompanyRequest = this.assigneComparableObject();
        this.betaService.getCompanyInformation(company, true);
    }

    public showChartFullSizeOlsBeta() {
        this.fullOlsBetaChart = null;
        this.fullOlsBetaChart = new Chart({
            chart: {
                height: 500,
                width: 1200,
                type: 'line',
            },

            tooltip: this.betaService.OlsBetaChart.options.tooltip,


            title: {
                text: ''
            },
            credits: {
                enabled: false
            },

            series: this.betaService.OlsBetaChart.options.series,
            xAxis: this.betaService.OlsBetaChart.options.xAxis,
            yAxis: this.betaService.OlsBetaChart.options.yAxis
        });

        this.isCompanyGraphviewlarg = true;
    }


    //#region Handiling CollapseExpand

    toggleLeverdCollapse() {
        this.betaService.isLeverdBetaCollapse = !this.betaService.isLeverdBetaCollapse;
    }

    togglebetaStatCollapse() {
        this.isBetaStatcollapse = !this.isBetaStatcollapse;
    }

    toggleunleverdinptCollapse() {
        this.isunlevinptCollapse = !this.isunlevinptCollapse;
    }

    toggleunleverBetaCollapse() {
        this.isUnleverdBetaCollapse = !this.isUnleverdBetaCollapse;
    }

    //#region 

    //#region  Releverd Betas Logic

    loadReleverdOnComponentLoad() {
        this.currency = this.betaService.metricInputs.Currency;
        if (this.betaService.isBetaEstimate) {
            this.releveredBetas.BetaType.value = this.betaService.releveredInputs.BetaType;
            this.releveredBetas.TargetCapital.value = this.betaService.releveredInputs.TargetCapitalStructure;
            this.releveredBetas.TargetCapital.context = this.betaService.releveredInputs.TargetLabel;
            this.releveredBetas.TargetCapital.source = this.betaService.releveredInputs.TargetCapitalStructure + ' - ' + this.betaService.releveredInputs.TargetLabel;
            this.releveredBetas.TaxRate.value = this.betaService.releveredInputs.TaxRate;
            this.releveredBetas.TaxRate.context = this.betaService.releveredInputs.TaxRateLabel;
            this.releveredBetas.TaxRate.source = this.betaService.releveredInputs.TaxRate + ' - ' + this.betaService.releveredInputs.TaxRateLabel;
            this.betaService.capitalRateSelectedLabel = this.betaService.releveredInputs.TargetLabel;
            this.betaService.taxRateSelectedLabel = this.betaService.releveredInputs.TaxRateLabel;
            this.capitalRatelabelshow = true;
            this.taxRatelabelshow = true;
        }
        this.betaService.clearReleveredBetas.subscribe((x) => {
            if (x === true) {
                this.betaService.taxRateSelected = '';
                this.betaService.capitalRateSelected = '';
                this.betaService.minimumSummaryValue = '';
                this.betaService.maximumSummaryValue = '';
                this.betaService.medianSummaryValue = '';
                this.betaService.averageSummaryValue = '';
                this.betaService.firstQuartileSummaryValue = '';
                this.betaService.thirdQuartileSummaryValue = '';
                this.betaService.targetCapitalAverageValue = '';
                this.betaService.targetCapitalMedianValue = '';
                this.betaService.releveredAverageTaxRate = '';
                this.betaService.releveredMedianTaxRate = '';
                this.betaService.releveredTaxRate = '';
                this.betaService.leveredminimumSummaryValue = '';
                this.betaService.leveredfirstQuartileSummaryValue = '';
                this.betaService.leveredaverageSummaryValue = '';
                this.betaService.leveredmedianSummaryValue = '';
                this.betaService.leveredthirdQuartileSummaryValue = '';
                this.betaService.leveredmaximumSummaryValue = '';
                this.betaService.unleveredMinimumSummaryValue = '';
                this.betaService.unleveredFirstQuartileSummaryValue = '';
                this.betaService.unleveredAverageSummaryValue = '';
                this.betaService.unleveredMedianSummaryValue = '';
                this.betaService.unleveredThirdQuartileSummaryValue = '';
                this.betaService.unleveredMaximumSummaryValue = '';
                this.betaService.releveredInputs = {} as ReleveredInputs;
                this.releveredBetas.BetaType.value = '';
                this.releveredBetas.TargetCapital.value = '';
                this.releveredBetas.TargetCapital.context = '';
                this.releveredBetas.TargetCapital.source = '';
                this.releveredBetas.TaxRate.value = '';
                this.releveredBetas.TaxRate.context = '';
                this.releveredBetas.TaxRate.source = '';
                this.betaService.capitalRateSelectedLabel = '';
                this.betaService.taxRateSelectedLabel = '';
                this.clearStatistics();

            }
        });
    }

    clearStatistics() {

        this.betaService.releveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };

        this.betaService.leveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };

        this.betaService.unleveredStatistics = {
            Minimum: null,
            FirstQuartile: null,
            Average: null,
            Median: null,
            ThirdQuartile: null,
            Maximum: null
        };


    }

    public onBetaTypeChange(input: any): void {
        const type = this.betaService.betaTypeSelected;
        this.betaService.betaTypeSelected = input.value;
        this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        this.betaService.getLeveredSummaryStatistics(this.betaService.betaTypeSelected);
        this.betaService.getUneveredSummaryStatistics(this.betaService.betaTypeSelected);
        if (type !== input.value && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }
    }

    public onCpitalRateChange(input: any, inputOptions: any[]): void {
        const capital = this.betaService.isBetaEstimate ? this.betaService.releveredInputs.TargetCapitalStructure : this.betaService.releveredInputs.OriginalTargetCapitalStructure;
        const capitalLabel = this.betaService.releveredInputs.TargetLabel;
        this.betaService.capitalRateSelected = input.value;
        this.betaService.originalTargetCapitalStructure = input.value;
        this.capitalRatelabelshow = true;
        const label = inputOptions.find((i) => i.id === input.id);
        const selectedRate = inputOptions.find((i) => i.value === input.value);
        if (label && selectedRate) {
            this.betaService.capitalRateSelectedLabel = label.source;
        } else {
            if (this.betaService.IsCompanyHiddenStatusChanging || this.betaService.isDeleteCompanyChange) {
                // if user is hiding/unhiding comapnies then we want to clear the capital rate and hence the capital rate label as well
                this.betaService.capitalRateSelectedLabel = "";
            } else {
                this.betaService.capitalRateSelectedLabel = "Custom";
            }
        }
        if (this.betaService.capitalRateSelectedLabel.includes("Average")) {
            this.betaService.capitalRateSelected = this.betaService.targetCapitalOriginalAverageValue;
        } else if (this.betaService.capitalRateSelectedLabel.includes("Median")) {
            this.betaService.capitalRateSelected = this.betaService.targetCapitalOriginalMedianValue;
        } else {
            this.betaService.capitalRateSelected = ((this.betaService.capitalRateSelected / (100 - this.betaService.capitalRateSelected)) * 100).toFixed(2);
        }
        if (this.betaService.betaTypeSelected) {
            this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        }
        if (capital !== input.value && capitalLabel !== this.betaService.capitalRateSelectedLabel && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }
    }

    public onTaxRateChange(input: any, inputOptions: any[]): void {
        const tax = this.betaService.taxRateSelected;
        const taxLabel = this.betaService.releveredInputs.TaxRateLabel;
        this.betaService.taxRateSelected = input.value;
        this.taxRatelabelshow = true;
        const label = inputOptions.find((i) => i.id === input.id);
        const selectedTax = inputOptions.find((i) => i.value === input.value);
        if (label && selectedTax) {
            this.betaService.taxRateSelectedLabel = label.source;
        } else {
            if (this.betaService.IsCompanyHiddenStatusChanging || this.betaService.isDeleteCompanyChange) {
                // if user is hiding/unhiding comapnies then we want to clear the taxrate and hence the taxrate label as well
                this.betaService.taxRateSelectedLabel = "";
            } else {
                this.betaService.taxRateSelectedLabel = "Custom";
            }
        }
        if (this.betaService.betaTypeSelected) {
            this.betaService.getReleveredSummaryStatistcs(this.betaService.betaTypeSelected);
        }
        if (tax !== input.value || taxLabel !== this.betaService.taxRateSelectedLabel && !this.betaService.IsCompanyHiddenStatusChanging && !this.betaService.isDeleteCompanyChange) {
            this.betaService.updateEstimate();
        }

        this.betaService.IsCompanyHiddenStatusChanging = false;
        this.betaService.isDeleteCompanyChange = false;
        // if (!this.isTaxRateChanged) {
        //     this.betaService.updateEstimate();
        //     this.isTaxRateChanged = true;
        // }
    }

    public toggleCollapse(): void {
        this.isReleverdcollapse = !this.isReleverdcollapse;
        this.insertBetaTypeOtions();
        this.betaService.insertTargetCapitalRateOptions();
        this.betaService.insertTaxRateReleveredOptions();

    }
    public insertBetaTypeOtions(): void {
        this.betaTypeOtions = [];
        this.betaService.betaTypeList.forEach((b: any) => {
            this.betaTypeOtions.push({
                name: b.name,
                source: b.name,
                value: b.value,
                type: ComboboxDataType.String
            });
        });
    }

    //#endregion


    //#region  leverdBetaData

    public openTrendsOverTimeModal(title: string): void {
        this.betaService.betas = [];
        // if (this.betaService.betasToTTitle !== title) {
        const companyRequest: CompanyReturnsRequest = {
            GVKEY: this.betaService.gvKeys,
            Metrics: this.betaService.metricInputs
        };

        const request = this.dataStore.getBetasToT(title, companyRequest);
        this.spinner.while(request);
        request.once(() => {
            const betas = this.dataStore.betasToT;
            betas.onceDefined((_beta) => {
                if (_beta) {
                    companyRequest.GVKEY.forEach((key) => {
                        const betaValues = _beta.filter((x: BetaToT) => x.Gvkey === key);
                        const dates = companyRequest.Metrics.Frequency === "Monthly" ? this.betaService.GetMonthlyDates(companyRequest, new Date(betaValues[0].DataAsOf)) : this.betaService.GetWeeklyDates(companyRequest, new Date(betaValues[0].DataAsOf));

                        if (dates.length > 0) {
                            dates.forEach((date) => {
                                let remainingDates: BetaToT = {
                                    Gvkey: key,
                                    DataAsOf: date,
                                    OlsBeta: null,
                                    SumBeta: null
                                };
                                this.betaService.betas.push(remainingDates)
                            });
                        }
                        betaValues.forEach((data) => {
                            this.betaService.betas.push(data);
                        });
                        this.betaService.betas.sort(function (a, b) {
                            return a.DataAsOf.localeCompare(b.DataAsOf)
                        });
                    });
                    //this.setLineChartOptions(this.betaService.betas);
                    this.primeManager.openDialog(BetaTrendsOverTimeComponent,
                        {
                            title: title,
                            frequency: companyRequest.Metrics.Frequency
                        });
                }
            });
        });
        // }

    }

    ngAfterViewInit(): void {
        const divElem = this.cmpnyRow.nativeElement
        const observer = new ResizeObserver(() => {
            this.cmpHeight = this.cmpnyRow.nativeElement.offsetHeight;
            this.stickyHeaderHeight = this.cmpHeight + 42 + "px";
        })
        observer.observe(divElem);
    }
    //#endregion

    private cancel() {
        this.displayTearSheet = false;
    }

    public handleMaximize(event: any) {
        setTimeout(() => {
            this.betaService.OlsBetaChart.ref.reflow();
        }, 50);
    }

}
