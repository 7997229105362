import { Component, OnInit } from '@angular/core';
import { EstimateTitle } from 'src/app/_api/enums/estimate-type';
import { EstimateSharedService } from 'src/app/home/estimates/estimate-shared-service';

@Component({
    selector: 'sic-to-gics-mapping',
    templateUrl: './sic-to-gics-mapping.component.html',
    styleUrls: ['./sic-to-gics-mapping.component.scss']
})
export class SICToGICSMappingComponent implements OnInit {

    constructor(private estimateSharedService: EstimateSharedService) { }

    ngOnInit(): void {
        this.estimateSharedService.setTitle(EstimateTitle.SicToGicsMapping);
    }

}
