import { NgModule } from '@angular/core';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ChartModule } from 'angular-highcharts';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModules } from '../_config/common-modules';
import { AddOrRemoveComponent } from './add-or-remove/add-or-remove.component';
import { BetaHomeComponent } from './beta-home/beta-home.component';
import { BetaStatisticsComponent } from './beta-statistics/beta-statistics.component';
import { BetaSummaryStatisticsComponent } from './beta-summary-statistics/beta-summary-statistics.component';
import { BetaComponent } from './beta.component';
import { BetaService } from './beta.service';
import { BetaModuleExcelExportService } from './betamoduleexcel-export.service';
import { ComparableCompanyModalComponent } from './comparable-company-modal/comparable-company-modal.component';
import { LeveredBetasComponent } from './levered-betas/levered-betas.component';
import { OperatorsComponent } from './operators/operators.component';
import { ReleveredBetasComponent } from './relevered-betas/relevered-betas.component';
import { UnleveredBetasComponent } from './unlevered-betas/unlevered-betas.component';
import { UnleveringInputsComponent } from './unlevering-inputs/unlevering-inputs.component';
import { BetaTrendsOverTimeComponent } from './beta-trends-over-time/beta-trends-over-time.component';
import { OlsBetaGraphComponent } from './olsBeta-graph-component';
import { CustomeNumberFormatPipe } from './custome-number-format-pipe';

@NgModule({
    declarations: [BetaComponent, BetaHomeComponent, ComparableCompanyModalComponent, AddOrRemoveComponent, BetaStatisticsComponent, BetaSummaryStatisticsComponent, OperatorsComponent, UnleveringInputsComponent, UnleveredBetasComponent, LeveredBetasComponent, ReleveredBetasComponent, BetaTrendsOverTimeComponent, OlsBetaGraphComponent, CustomeNumberFormatPipe],
    imports: [
        ...CommonModules,
        ConfirmDialogModule,
        DialogModule,
        ChartModule,
        ExcelExportModule,
        TooltipModule

    ],
    entryComponents: [ComparableCompanyModalComponent, AddOrRemoveComponent, BetaTrendsOverTimeComponent],
    providers: [BetaService, BetaModuleExcelExportService, ConfirmationService]
})
export class BetaModule { }
