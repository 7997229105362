import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionType, UserResponse } from 'src/app/_api/responses/user.response';
import { CommonDate } from 'src/app/_navigator/common/date-struct';
// import { CommonDate } from 'src/app/_navigator/common/date-struct';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { HelpService } from 'src/app/_navigator/help/help.service';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { BetaService } from '../beta.service';
import { ComaparableCompany, ComaparableCompanyFilter, CompanyInformation, CompanyReturnsRequest, GeneralInputs, ReleveredInputs, UnleveringTaxRateInputs } from '../beta.types';
import { Spinner } from '@concurrency/angular';
import { GicIndustry } from 'src/app/_api/responses/company-returns.response';
import { Chart } from 'angular-highcharts';
import { OlsBetaGraphComponent } from '../olsBeta-graph-component';


@Component({
    selector: 'comparable-company-modal',
    templateUrl: './comparable-company-modal.component.html',
    styleUrls: ['./comparable-company-modal.component.scss']
})
export class ComparableCompanyModalComponent implements OnInit {

    public display = true;
    public childDisplay = false;
    public unleveringtaxRateInputs: UnleveringTaxRateInputs[] = [];
    public generalInputs: GeneralInputs = {} as GeneralInputs;
    public companies = [];
    public NumberFormatUtil = NumberFormatUtil;
    public dialogHeader = 'Add a Comparable Company';
    public obseravationOperators: any[] = [];
    public inputValue = '';
    public refreshOperator = true;
    public isSearchClicked = false;
    public operators: any;
    public selectedFilter = "";
    public text: any;
    public Help: any = HelpText;
    public onSearchUpdate = new Subject<any>();
    public isCompanyDisplay = false;
    public selectedCompany?: ComaparableCompany;
    public isEnterpriseorPro = false;
    public userdata!: UserResponse;
    public CompanyInfo?: CompanyInformation;
    public Currency?: string;
    public isIndustryShow = false;
    public industryInput?: string;
    public lstIndustryCodeDescription!: GicIndustry[];
    public tempIndustryCodeDescription!: GicIndustry[];
    public fullOlsBetaChart: any;
    public betaChart: any;
    public isCompanyGraphviewlarg = false;
    public showCompanyTearSheet: boolean = false;
    public tableHeight!: string;
    @ViewChild('filterSection', { static: false }) filterSection!: ElementRef;
    @ViewChild('contentsection', { static: false }) modalContentSection!: ElementRef
    @ViewChild(OlsBetaGraphComponent) olsBetaGraphComponent!: OlsBetaGraphComponent;


    constructor(
        public betaService: BetaService,
        public primeManager: PrimeManager,
        public helpService: HelpService,
        private userStore: UserStore,
        private spinner: Spinner,


    ) {
        this.onSearchUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe((searchObject: any) => {
                this.onSearch(searchObject.value, searchObject.filter);
            });
    }

    public ngOnInit(): void {
        this.betaService.tooltipDisabled();
        this.betaService.selectedCompanies = JSON.parse(JSON.stringify(this.betaService.finalSelectedCompanies));

        this.userStore.user.onceDefined((user) => {
            this.userdata = user;
            if (this.userdata.Subscriptions.find((x) => x.Type === SubscriptionType.BtaEnterprise || x.Type === SubscriptionType.BtaPro || x.Type === SubscriptionType.BtaBasic)) {
                console.log(this.userdata.Subscriptions);

                this.isEnterpriseorPro = true;
            }

        });
        this.operators = this.betaService.filterOperators;
        this.betaService.clearSelectedFilters();
    }

    public isDisableSubmit(): boolean {
        const a = this.betaService.selectedCompanies;
        const b = this.betaService.finalSelectedCompanies;
        const isSame = (left: any, right: any) => left.id === right.id && left.CompanyName === right.CompanyName;
        const onlyInLeft = (left: any, right: any, compare: any) =>
            left.filter((leftValue: any) =>
                !right.some((rightValue: any) =>
                    compare(leftValue, rightValue)));
        const onlyInA = onlyInLeft(a, b, isSame);
        const onlyInB = onlyInLeft(b, a, isSame);
        const result = [...onlyInA, ...onlyInB];
        if (result.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public submit(): void {
        this.betaService.isSubmitClicked = true;
        if (this.betaService.isBetaEstimate) {
            this.betaService.finalSelectedCompanies = [];
        }
        this.betaService.finalSelectedCompanies = JSON.parse(JSON.stringify(this.betaService.selectedCompanies));
        this.betaService.subjectCompanyLocationId = this.generalInputs.SubjectCompanyLocation.id;
        this.betaService.betaValuationDate = this.generalInputs.ValuationDate;
        this.display = false;
        this.betaService.isClickableAllowed = false;
        this.betaService.generalInputsModified = false;

        if (this.betaService.finalSelectedCompanies.length > 0) {
            this.betaService.isFormDisable.next(true);
            // const dates: any[] = [];
            // const countryIds: any[] = [];
            this.unleveringtaxRateInputs = [];
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                this.unleveringtaxRateInputs.push({
                    GVKEY: company.GVKEY,
                    CountryCode: company.IncorporationCountry,
                    DataAsOf: CommonDate.fromStruct(this.generalInputs.ValuationDate).asDate()
                });
            });
            if (this.generalInputs.SubjectCompanyLocation.id) {
                const valuationDate = CommonDate.fromStruct(this.generalInputs.ValuationDate).asString();
                this.betaService.getTaxRateList(this.unleveringtaxRateInputs);
                this.betaService.getTaxRate(this.generalInputs.SubjectCompanyLocation.id, valuationDate);
            }
            this.betaService.gvKeys = [];
            this.betaService.finalSelectedCompanies.forEach((company: any) => {
                company.TrendsOverTimeDisplay = true;
                this.betaService.gvKeys.push(company.GVKEY);
            });
            const companyRequest: CompanyReturnsRequest = {
                GVKEY: this.betaService.gvKeys,
                Metrics: this.betaService.metricInputs
            };
            this.betaService.getLeveredBetas(companyRequest, this.betaService.unleveredBetas, this.betaService.unleveringInputs);
            // this.betaService.getBetaStatistics(companyRequest);
            this.betaService.getCompanyReturns(companyRequest);

            this.betaService.taxRateSelected = '';
            this.betaService.capitalRateSelected = '';
            this.betaService.minimumSummaryValue = '';
            this.betaService.maximumSummaryValue = '';
            this.betaService.medianSummaryValue = '';
            this.betaService.averageSummaryValue = '';
            this.betaService.firstQuartileSummaryValue = '';
            this.betaService.thirdQuartileSummaryValue = '';
            this.betaService.targetCapitalAverageValue = '';
            this.betaService.targetCapitalMedianValue = '';
            this.betaService.releveredAverageTaxRate = '';
            this.betaService.releveredMedianTaxRate = '';
            this.betaService.releveredTaxRate = '';
            this.betaService.leveredminimumSummaryValue = '';
            this.betaService.leveredfirstQuartileSummaryValue = '';
            this.betaService.leveredaverageSummaryValue = '';
            this.betaService.leveredmedianSummaryValue = '';
            this.betaService.leveredthirdQuartileSummaryValue = '';
            this.betaService.leveredmaximumSummaryValue = '';
            this.betaService.unleveredMinimumSummaryValue = '';
            this.betaService.unleveredFirstQuartileSummaryValue = '';
            this.betaService.unleveredAverageSummaryValue = '';
            this.betaService.unleveredMedianSummaryValue = '';
            this.betaService.unleveredThirdQuartileSummaryValue = '';
            this.betaService.unleveredMaximumSummaryValue = '';
            this.betaService.returnFrequencyOptions = [];
            this.betaService.subjectCompanyLocationOptions = [];
            this.betaService.currencyofwaccoptions = [];
            this.betaService.marketIndexOptions = [];
            this.betaService.lookbackPeriodOptions = [];
            this.betaService.betas = [];
            this.betaService.releveredInputs = {} as ReleveredInputs;
        } else {
            this.betaService.isFormDisable.next(false);
            if (this.betaService.seriesLength > 1) {
                for (let i = this.betaService.seriesLength; i >= 1; i--) {
                    this.betaService.chart.removeSeries(i);
                }
                this.betaService.chart.options.series.splice(1, this.betaService.seriesLength);
            }
        }
        this.betaService.betasUpdated = false;
    }

    public addOrRemoveColumns(): void {
        this.childDisplay = true;
    }

    public changeDisplay(event: any): void {
        this.childDisplay = event;
    }
    public observationsColor(value: any): boolean {
        let isgreay = false;
        if (this.generalInputs.ReturnFrequency.value === 'Monthly' && this.generalInputs.LookbackPeriod) {
            switch (this.generalInputs.LookbackPeriod.value) {
                case '1-Year':
                    isgreay = value < 12 ? true : false;
                    break;
                case '2-Year':
                    isgreay = value < 24 ? true : false;
                    break;
                case '3-Year':
                    isgreay = value < 36 ? true : false;
                    break;
                case '4-Year':
                    isgreay = value < 48 ? true : false;
                    break;
                case '5-Year':
                    isgreay = value < 60 ? true : false;
                    break;
                default:
                    break;
            }

        } else if (this.generalInputs.ReturnFrequency.value === 'Weekly' && this.generalInputs.LookbackPeriod) {
            switch (this.generalInputs.LookbackPeriod.value) {
                case '1-Year':
                    isgreay = value < 52 ? true : false;
                    break;
                case '2-Year':
                    isgreay = value < 104 ? true : false;
                    break;
                case '3-Year':
                    isgreay = value < 156 ? true : false;
                    break;
                case '4-Year':
                    isgreay = value < 208 ? true : false;
                    break;
                case '5-Year':
                    isgreay = value < 260 ? true : false;
                    break;
                default:
                    break;
            }
        }
        return !isgreay;

    }

    /*
    Prevent user from selecting a company if no. of observations are less than the
    Return Frequency and Lookback Period selected. This applies to both 'Monthly' and 'Weekly' Return Frequency
    eg. Return frequencey selected = "Monthly", loopback period selectd = 4 years
    now, if any comapany has no. of observations less than 48 months, then user cannot select that company.
    */
    public disableCompanySelection(value: any): boolean {
        let disableCheckbox = false;
        if (this.generalInputs.ReturnFrequency.value === 'Monthly' && this.generalInputs.LookbackPeriod) {
            switch (this.generalInputs.LookbackPeriod.value) {
                case '1-Year':
                    disableCheckbox = value < 12 ? true : false;
                    break;
                case '2-Year':
                    disableCheckbox = value < 24 ? true : false;
                    break;
                case '3-Year':
                    disableCheckbox = value < 36 ? true : false;
                    break;
                case '4-Year':
                    disableCheckbox = value < 48 ? true : false;
                    break;
                case '5-Year':
                    disableCheckbox = value < 60 ? true : false;
                    break;
                default:
                    break;
            }

        } else if (this.generalInputs.ReturnFrequency.value === 'Weekly' && this.generalInputs.LookbackPeriod) {
            switch (this.generalInputs.LookbackPeriod.value) {
                case '1-Year':
                    disableCheckbox = value < 52 ? true : false;
                    break;
                case '2-Year':
                    disableCheckbox = value < 104 ? true : false;
                    break;
                case '3-Year':
                    disableCheckbox = value < 156 ? true : false;
                    break;
                case '4-Year':
                    disableCheckbox = value < 208 ? true : false;
                    break;
                case '5-Year':
                    disableCheckbox = value < 260 ? true : false;
                    break;
                default:
                    break;
            }
        }
        return disableCheckbox;

    }

    public onSearch(eventvalue: any, filter: any): void {
        if (filter.keyOfFilter === 'CompanyName') {
            this.betaService.betaComparbleCompanyRequest.companyName = eventvalue;
        } else if (filter.keyOfFilter === 'Observations') {
            this.betaService.betaComparbleCompanyRequest.observations = eventvalue;
        } else if (filter.keyOfFilter === 'IndustryCodeDescription') {
            this.betaService.betaComparbleCompanyRequest.industry = eventvalue;
        } else if (filter.keyOfFilter === 'KeyWords') {
            this.betaService.betaComparbleCompanyRequest.keyWords = eventvalue;
        } else if (filter.keyOfFilter === 'Sales') {
            this.betaService.betaComparbleCompanyRequest.sales = eventvalue;
        } else if (filter.keyOfFilter === 'EBITDA') {
            this.betaService.betaComparbleCompanyRequest.EBITDA = eventvalue;
        } else if (filter.keyOfFilter === 'Assets') {
            this.betaService.betaComparbleCompanyRequest.assets = eventvalue;
        } else if (filter.keyOfFilter === 'MarketCap') {
            this.betaService.betaComparbleCompanyRequest.marketCap = eventvalue;
        } else if (filter.keyOfFilter === 'IncorporationCountry') {
            this.betaService.betaComparbleCompanyRequest.incorporationCountry = eventvalue;
        } else if (filter.keyOfFilter === 'HeadquarterCountry') {
            this.betaService.betaComparbleCompanyRequest.headquarterCountry = eventvalue;
        } else if (filter.keyOfFilter === 'Exchange') {
            this.betaService.betaComparbleCompanyRequest.exchange = eventvalue;
        } else if (filter.keyOfFilter === 'TotalDebt') {
            this.betaService.betaComparbleCompanyRequest.debt = eventvalue;
        }

    }

    public showHelpText(selectedHeader: ComaparableCompanyFilter): void {
        this.selectedFilter = selectedHeader.keyOfFilter;
        if (this.selectedFilter === "Observations") {
            this.helpService.toggle(this.selectedFilter, HelpText.Observations);
        } else if (this.selectedFilter === "KeyWords") {
            this.helpService.toggle(this.selectedFilter, HelpText.KeyWords);
        } else if (this.selectedFilter === "CompanyName") {
            this.helpService.toggle(this.selectedFilter, HelpText.Company);
        }
    }

    public onInputChange(eventvalue: any, filterName: any): void {
        this.onSearchUpdate.next({ value: eventvalue, filter: filterName });
    }

    public OnCompanyClick(selectedCompany: any): void {
        this.betaService.isCompanyDisplay = false;
        this.betaService.getCompanyInformation(selectedCompany, false);
        this.showCompanyTearSheet = !this.showCompanyTearSheet;
    }

    public GetIndustries(): void {
        this.industryInput = "";
        var request = this.betaService.getGicList();
        this.spinner.begin();

        request.once((data) => {
            data = data.sort((a, b) => a.GicCode.localeCompare(b.GicCode, undefined, { numeric: true }))

            // sort((a, b) => {
            //     return Number(a.GicCode) > Number(b.GicCode) ? 1 : Number(a.GicCode) == Number(b.GicCode) ? 0 : -1
            // });
            this.lstIndustryCodeDescription = data;
            this.tempIndustryCodeDescription = data;
            this.spinner.end();
            this.isIndustryShow = true;
        })


    }

    public onIndustryChange(inputValue: any): void {
        if (inputValue !== '') {
            if (!isNaN(Number(inputValue))) {
                this.lstIndustryCodeDescription = this.tempIndustryCodeDescription.filter((s) => s.GicCode.startsWith(inputValue, 0));
            } else {
                this.lstIndustryCodeDescription = this.tempIndustryCodeDescription.filter((s) => s.GicDescription?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);

            }
        } else {
            this.lstIndustryCodeDescription = this.tempIndustryCodeDescription;
        }

    }


    public clearFields() {
        this.operators = [];
        this.betaService.clearFields()
    }

    public numberWithCommas(value: any): string {
        return NumberFormatUtil.numberWithCommas(value, 1);
    }

    public showChartFullSizeOlsBeta() {
        this.fullOlsBetaChart = null;

        this.fullOlsBetaChart = new Chart({
            chart: {
                height: 500,
                width: 1200,
                type: 'line',
            },


            tooltip: this.betaService.OlsBetaChart.options.tooltip,

            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: this.betaService.OlsBetaChart.options.series,
            xAxis: this.betaService.OlsBetaChart.options.xAxis,
            yAxis: this.betaService.OlsBetaChart.options.yAxis
        });

        this.isCompanyGraphviewlarg = true;
    }

    private back() {
        this.showCompanyTearSheet = false;
    }

    private cancel() {
        this.display = false;
    }

    public getPlaceHolder(keyOfFilter: string): string {

        let headerName = this.betaService.companyHeaders.getHeaderName(keyOfFilter);
        if (headerName === 'Industry (GICS) Code – Description') {
            return 'Industry Code or Description';
        } else if (headerName === '# of Observations') {
            return '# of Observations'
        } else {
            return headerName;
        }

    }

    public showHideFilter() {
        this.betaService.showFilters = !this.betaService.showFilters;
        if (this.betaService.showFilters === true) {
            this.betaService.selectedFilters.push(...this.betaService.comparableFilters);
        } else {
            this.betaService.selectedFilters = this.betaService.selectedFilters.filter((d) => !this.betaService.comparableFilters.find((s) => s.keyOfFilter === d.keyOfFilter));
        }

    }

    ngAfterContentChecked() {
        this.setTableHeaderFixedPosistion();

    }

    ngAfterViewInit() {
        this.setTableHeaderFixedPosistion();
    }


    public setTableHeaderFixedPosistion() {
        var dialogContent = document.getElementsByClassName('p-dialog-content')[0]
        if (this.filterSection && this.filterSection?.nativeElement && dialogContent) {
            const modalContentSection = dialogContent.clientHeight;
            const filterHeight = this.filterSection.nativeElement.offsetHeight
            this.tableHeight = (modalContentSection - (filterHeight + 18)) + "px";
        }

    }

    public handleMaximize(event: any) {
        if (this.olsBetaGraphComponent) {
            this.olsBetaGraphComponent.handleMaximize();
        }
    }



}
