import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Decile, DecileData } from '../responses/decile.response';
import { Auth0ApiClient } from '../api.client';
import { CrspCharacteristicsData, CrspCharacteristicsWithPortfolioData } from 'src/app/costofcapital-inputs/models/crspCharacteristicData';
import { DataSourcePayLoad } from 'src/app/costofcapital-inputs/models/DataSourceDetails';


@Injectable()
export class MeasureClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public readSp(dataAsOf: string, marketCap: number): Observable<DecileData> {
        return this.get(`measure/targetDecile?DataAsOf=${dataAsOf}&marketCap=${marketCap}`);
    }

    public getDeciles(dataAsOf: string): Observable<Decile[]> {
        return this.get(`measure/Deciles?dataAsOf=${dataAsOf}`);
    }

    public getAboutExcelDetails(dataSourcePayload: DataSourcePayLoad): Observable<any> {
        return this.post(`measure/GetSourceDetails`, dataSourcePayload);
    }

    public getCrspCharcteristicData(dataAsOf: string): Observable<CrspCharacteristicsWithPortfolioData> {
        return this.get(`measure/CrspCharacteristics?dataAsOf=${dataAsOf}`);
    };


}
