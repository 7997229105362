import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customeNumberFormatPipe',
})

export class CustomeNumberFormatPipe implements PipeTransform {

    transform(value: number): string {
        console.log(value)
        return value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    }
}
