import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';


@Component({
    selector: 'beta-graph',
    template: `<div  *ngIf="data" [chart]="data"> </div>`
})


export class OlsBetaGraphComponent implements OnChanges {

    public data!: any;
    @Input() graphData!: any;


    ngOnChanges(changes: SimpleChanges) {
        if ((changes['graphData'])) {
            if (this.graphData) {
                this.data = null;
                this.data = new Chart({
                    chart: {
                        height: 330,
                        type: 'line',
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },


                    title: {
                        text: ''
                    },
                    tooltip: this.graphData.options.tooltip,
                    credits: {
                        enabled: false
                    },
                    series: this.graphData.options.series,
                    xAxis: this.graphData.options.xAxis,
                    yAxis: this.graphData.options.yAxis
                });
            }
        }
    }

    public handleMaximize() {
        if (this.data) {
            setTimeout(() => {
                this.data.ref.reflow();
            }, 50);
        }
    }

}