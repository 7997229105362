import { Injectable } from '@angular/core';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { Spinner } from '@concurrency/angular';
import { Observable } from 'rxjs';
import { CrspCharacteristicsData, CrspCharacteristicsWithPortfolioData } from '../models/crspCharacteristicData';

@Injectable({
    providedIn: 'root'
})

export class SizePremiumService {

    public crspCharacteristicData!: CrspCharacteristicsData[];
    public crspCharacteristicPortfolioData!: CrspCharacteristicsData[];



    constructor(private measureClient: MeasureClient, private spinner: Spinner) {


    }


    public getCharacteristicData(dataAsOf: string): Observable<CrspCharacteristicsWithPortfolioData> {
        return this.measureClient.getCrspCharcteristicData(dataAsOf)

    }


}